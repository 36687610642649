import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const success = (msg: string) => {
    return toast.success(msg, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: false,
        progress: undefined,
    });
};
export const failed = (msg: string) =>
    toast.error(msg, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: false,
        progress: undefined,
    });
export const warn = (msg: string) => {
    return toast.warn(msg, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: false,
        progress: undefined,
    });
};
