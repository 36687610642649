import * as yup from "yup";

export interface ProfileData {
    given_name: string;
    family_name: string;
    picture: string;
    email: string;
    profileImage: FileList;
}
export interface IChangePassword {
    password: string;
    confirm_password: string;
}
export const cpwdSchema = yup.object().shape({
    password: yup.string()
                .required("Required password field")
                .matches(/^(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,"Must be 8 or more characters and contain at least 1 number and 1 special character "),
    confirm_password: yup.string()
                .oneOf([yup.ref('password')], 'Passwords must match')
})

const SUPPORTED_FORMATS = ["image/jpg", "image/png", "image/jpeg"];
export const schema = yup.object().shape({
    profileImage: yup
        .mixed<FileList>() // Pass in the type of `fileUpload`
        .test(
            "fileSize",
            "Image size should be less than 1MB.",
            (files) =>
                !files || // Check if `files` is defined
                files.length === 0 || // Check if `files` is not an empty list
                Array.from(files).every((file) => file.size <= 1_000_000)
        )
        .test(
            "format",
            "Image should be of jpeg, jpg, png type",
            (files) =>
                !files || // Check if `files` is defined
                files.length === 0 || // Check if `files` is not an empty list
                Array.from(files).every((file) => SUPPORTED_FORMATS.includes(file.type))
        ),
    given_name: yup
        .string()
        .max(15, "Must be 15 characters or less")
        .required("Required first name field")
        .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
    family_name: yup
        .string()
        .max(15, "Must be 15 characters or less")
        .required("Required last name field")
        .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
});