import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import { Box, Typography, IconButton } from "src/UILibrary";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import { Button } from "@mui/material";
import chartRating from "src/assets/image/WestEnd.png";
import chartLollipop from "src/assets/image/chartLollipop.png";
import chartRatings from "src/assets/image/chartRatings.png";

interface Props {
    openDrawer: boolean;
    handleCloseDrawer: () => void;
}
const drawerWidth = 475;
const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
}));
const DetailsDrawer: React.FC<Props> = ({ openDrawer, handleCloseDrawer }) => {
    return (
        <>
            <Drawer
                anchor="left"
                open={openDrawer}
                onClose={handleCloseDrawer}
                // variant="persistent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                        width: drawerWidth,
                        boxSizing: "border-box",
                        backgroundColor: "#fff",
                        border: "0.1px solid #fff",
                        borderRadius: "8px",
                        // marginTop: "70px",
                    },
                }}
            >
                <DrawerHeader sx={{ height: "69px", background: "#084c80" }}>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                        <Box sx={{ width: "170px", color: "#fff" }}>
                            <Typography.DetailsHeading>Site Details</Typography.DetailsHeading>
                        </Box>
                    </Box>
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseDrawer}
                        size="large"
                        sx={{
                            position: "absolute",
                            right: 10,
                            top: 10,
                            color: "#fff",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <Box role="presentation" sx={{ height: "75vh", overflow: "auto", p: "0 16px" }}>
                    <img src={chartRating} alt="Rating" />
                    <Divider />
                </Box>
                <Box role="presentation" sx={{ height: "75vh", overflow: "auto", p: "0 16px" }}>
                <img src={chartLollipop} alt="Rating" />
                    <Divider />
                </Box>
                <Box role="presentation" sx={{ height: "75vh", overflow: "auto", p: "0 16px" }}>
                <img src={chartRatings} alt="Rating" />
                    <Divider />
                </Box>
                <Box role="presentation" sx={{ height: "75vh", overflow: "auto", p: "0 16px", justifyConten: "center", alignItems: "center"}}>
                    <div style={{textAlign: "center"}}>
                        <Button variant="outlined" disabled>Generate Report</Button>
                    </div>
                </Box>
            </Drawer>
        </>
    );
};

export default DetailsDrawer;
