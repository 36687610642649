import React, { useEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Link } from "react-router-dom";
import NoImageAvailable from "../../assets/image/no-picture-available-icon.png";
// import Tooltip from "@mui/material/Tooltip";
import { useExplore } from "src/context/ExploreContext";
import CustomSpinner from "../../components/common/CustomSpinner";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAutocomplete } from "src/context/AutocompleteContext";

interface IPropertiesProps {
    locationProperties: any;
    isAvlLoading: boolean;
}
const AvailableProperties: React.FC<IPropertiesProps> = ({ locationProperties, isAvlLoading }) => {
    const selectedItemRef = useRef<HTMLDivElement>(null); //userRef for scrolling into associated item in the list when cliked on the marker on map
    const { selectedLocation, setSelectedLocation, setShowDetailInfowindow, setInfoWndPos } = useExplore();
    const { setBoundaries, setCenterPos } = useAutocomplete();
    /*------@Handler function for setting selected location--------- */
    const handleLocationAddress = (lat: number, lng: number, propertyId: number) => {
        setSelectedLocation({ lat: lat, lng: lng, filterType: "avlproperty", propertyId: propertyId });
        setShowDetailInfowindow({ infoType: "", mainMarkerFlag: false, avlPropertyFlag: true, localBusinessFlag: false });
        setBoundaries([]); // Clear Ploygonal boundary around main marker on map
        setCenterPos(undefined); // Clear main marker on the map
        setInfoWndPos(null); // Close main marker infowindow on the map
    };
    /*-----------@useEffect hook for scrolling into selected item in the list----------- */
    useEffect(() => {
        if (selectedItemRef?.current) {
            selectedItemRef?.current?.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "start",
            });
        }
    }, [selectedLocation]);

    return (
        <>
            {isAvlLoading ? (
                <Box sx={{ height: "550px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <CustomSpinner />
                </Box>
            ) : (
                <Box className="list-details" sx={{ p: "0px 14px" }}>
                    <Typography gutterBottom color={"entry"} sx={{ color: "#009dee", p: "6px", fontWeight: "normal", fontSize: { xs: "1.2rem", md: "1.5rem" } }} align="center">
                        {locationProperties?.length ? "Available Properties" : ""}
                    </Typography>
                    {locationProperties?.length ? (
                        locationProperties?.map((list: any) => (
                            <Box
                                key={list?.property_id}
                                sx={{
                                    mb: "7px",
                                    border: selectedLocation && selectedLocation?.propertyId === list?.property_id ? "2px solid rgba(0,0,0,0.7)" : "none",
                                    borderRadius: selectedLocation && selectedLocation?.propertyId === list?.property_id ? "6px" : "none",
                                }}
                                ref={selectedLocation && selectedLocation?.propertyId === list?.property_id ? selectedItemRef : null}
                                // sx={{ mb: "7px" }}
                                onClick={() => handleLocationAddress(list?.lat, list?.lng, list?.property_id)}
                            >
                                <Accordion
                                    sx={{
                                        background: "rgba(211,211,211,0.3)",
                                    }}
                                    //onClick={() => handleLocationAddress(list?.lat, list?.lng, list?.property_id)}
                                >
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                        <Typography gutterBottom color="#000" align="left" sx={{ fontSize: "1rem" }}>
                                            {list?.address}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Box>
                                            <img src={list?.image_url ? list?.image_url : NoImageAvailable} height="150" width="100%" alt="Property Image" />
                                        </Box>
                                        <Box className="flex-simple">
                                            <Typography component="legend" className="listing-heading flex-space">
                                                Price
                                            </Typography>
                                            <Typography gutterBottom variant="subtitle1" className="flex-space">
                                                {list?.price ? list?.price : "Not Available"}
                                            </Typography>
                                        </Box>
                                        <Box className="flex-simple">
                                            <Typography component="legend" className="listing-heading flex-space">
                                                Property type
                                            </Typography>
                                            <Typography gutterBottom variant="subtitle1" className="flex-space">
                                                {list?.property_type}
                                            </Typography>
                                        </Box>
                                        <Box className="flex-simple">
                                            <Typography component="legend" className="listing-heading flex-space">
                                                Property Area
                                            </Typography>
                                            <Typography gutterBottom variant="subtitle1" className="flex-space">
                                                {list?.property_area ? list?.property_area : "Not Available"}
                                            </Typography>
                                        </Box>
                                        {list?.address && (
                                            <Box className="flex-simple">
                                                {/* <Tooltip title="Click to see location"> */}
                                                <Link
                                                    to=""
                                                    style={{ textDecoration: "none", display: "flex", color: "rgba(0,0,238,0.7)" }}
                                                    // onClick={() => handleLocationAddress(list?.lat, list?.lng, list?.property_id)}
                                                >
                                                    <LocationOnIcon />
                                                    {list?.address}
                                                </Link>
                                                {/* </Tooltip> */}
                                            </Box>
                                        )}
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                        ))
                    ) : (
                        <>
                            <Typography gutterBottom color={"entry"} sx={{ color: "#009dee", fontWeight: "normal", fontSize: { xs: "1.2rem", md: "1.5rem" } }} align="center">
                                {"No Available Properties"}
                            </Typography>
                        </>
                    )}
                </Box>
            )}
        </>
    );
};

export default AvailableProperties;
