import { User, ResetPasswordCred } from "src/types";
import { axiosApiClient } from "./apiClient";
export const getCSRFToken = async () => {
  const { data } = await axiosApiClient.get("helloworld/csrf");
  return data;
};

//Api call for user login
export const userLogin = async (user: User,) => {
  return await axiosApiClient.post("auth/login", user, {
      withCredentials:true,
      headers: {
        "Access-Control-Allow-Origin": "http://localhost:3000"
      },
    });
};

//Api call for user logout
export const userLogout = async () => {
  return await axiosApiClient.post("auth/logout");
};

//Api call for register user 
export const userRegister = async (user: User) => {
  return await axiosApiClient.post("auth/signup", user);
};

//Api call for forgot password
export const forgotPassword = async (user: Partial<User>) => {
  return await axiosApiClient.post("auth/request_password_reset", user, );
};

//Api call for reset password
export const resetPassword = async (data: ResetPasswordCred) => {
  const apiData = {
    password: data?.password,
    confirm_password: data?.confirm_password
  }
  return data?.reset_token ? await axiosApiClient.post(`auth/resetpassword/${data?.reset_token}`, apiData ) : await axiosApiClient.post(`auth/resetpassword`, apiData , {withCredentials: true} )
};