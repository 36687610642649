import React from "react";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";

export const Button: React.FC<
  Omit<LoadingButtonProps, "color"> & {
    shape?: "normal" | "round";
    color?:
      | "inherit"
      | "primary"
      | "secondary"
      | "third"
      | "success"
      | "error"
      | "info"
      | "warning"
      | "google"
      | "line"
      | "entry"
      | "chip";
    component?: string;
  }
> = ({ size = "medium", shape = "normal", children, sx, ...rest }) => {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <LoadingButton
      sx={{
        fontSize: size === "small" ? 12 : size === "medium" ? 18 : 16,
        lineHeight: 1,
        fontWeight: 700,
        textTransform: "capitalize",
        borderRadius: shape === "normal" ? 1 : 10,
        py: size === "small" ? 1 : size === "medium" ? { xs: 1, md: 1.5 } : 1.5,
        px: size === "small" ? 2.5 : size === "medium" ? 4.5 : 3,
        ...sx,
      }}
      {...rest}
    >
      {children}
    </LoadingButton>
  );
};
