import { createSlice } from "@reduxjs/toolkit";

interface UserDefaultType {
    userDefaultsData : [{
        business_type: string,
        lat: number,
        lng: number,
        address: string,
    }]
       
}
const initialState: UserDefaultType =  {
    userDefaultsData : [{
         business_type: "",
         lat: 0,
         lng: 0,
         address: "",
    }]
    
}

export const userDefaultSlice = createSlice({
    name: "userDefault",
    initialState,
    reducers: {
        set_user_defaults : (state, action) => {
            state.userDefaultsData = action.payload;
        },
        update_user_defaults(state, action) {
            state.userDefaultsData = action.payload;
        },
        reset_user_defaults : (state) => {
            state.userDefaultsData = [{
                business_type: "",
                lat: 0,
                lng: 0,
                address: "",
           }]
        }
        
    },
    
});
    
  export const { set_user_defaults, update_user_defaults, reset_user_defaults } = userDefaultSlice.actions;
  
  export default userDefaultSlice.reducer;

