import React, { useState } from "react";
import { Box, Grid, TextField, useTheme, componentSx, Typography, Button, IconButton, InputAdornment, Visibility, VisibilityOff } from "src/UILibrary";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ResetPasswordCred } from "src/types";
import landingBG from "../../assets/image/grey-map.jpg";
import { resetPassword } from "src/modules/api/actions/auth";
import { failed, success } from "src/components/common/Toastify";

const ResetPassword: React.FC = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const { MapContainerSx } = componentSx(theme);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<ResetPasswordCred>({
        mode: "all",
        defaultValues: {
            password: "",
            confirm_password: "",
            reset_token: "",
        },
    });
    /* @Form Submit handler function*/
    const onSubmitHandler = async (dt: ResetPasswordCred) => {
        try {
            setSubmitting(true);
            const result = await resetPassword(dt);
            if (result?.data?.success === true) {
                success(result?.data?.message);
                navigate("/login");
            } else {
                failed(result?.data?.error);
            }
        } catch (error: any) {
            failed(error?.response?.data?.errors?.message || error?.message);
        } finally {
            setSubmitting(false);
        }
    };

    const onSubmitErrorHandler = () => {
        console.log(errors);
    };

    return (
        <Box
            sx={{
                ...MapContainerSx,
                height: "100vh",
                backgroundImage: `url(${landingBG})`,
            }}
        >
            <Box
                sx={{
                    maxWidth: 460,
                    width: "100%",
                    mx: "auto",
                    p: 4,
                    bgcolor: "google.main",
                    borderRadius: 2,
                    boxShadow: "0 0 10px rgba(0,0,0,.3)",
                    position: "relative",
                }}
            >
                <Box component="form" onSubmit={handleSubmit(onSubmitHandler, onSubmitErrorHandler)}>
                    <Grid container rowSpacing={3} columnSpacing={3} justifyContent={"center"}>
                        <Grid item xs={12}>
                            <Typography.SubHeading color={"primary"} align="center">
                                Reset your password
                            </Typography.SubHeading>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                size="small"
                                type={showPassword ? "text" : "password"}
                                label="Password"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton aria-label="toggle password visibility" onClick={() => setShowPassword(!showPassword)}>
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                {...register("password", {
                                    required: {
                                        value: true,
                                        message: "Password is required",
                                    },
                                    validate: {
                                        password: (v) =>
                                            /^(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/.test(v) ||
                                            "Must be 8 or more characters and contain at least 1 number and 1 special character ",
                                    },
                                })}
                                helperText={errors?.password?.message}
                                error={!!errors?.password?.message}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                size="small"
                                type={showConfirmPassword ? "text" : "password"}
                                label="Confirm Password"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton aria-label="toggle password visibility" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                {...register("confirm_password", {
                                    required: {
                                        value: true,
                                        message: "Password is required",
                                    },
                                    validate: (val: string) => {
                                        if (watch("password") !== val) {
                                            return "Your passwords do no match";
                                        }
                                    },
                                })}
                                helperText={errors?.confirm_password?.message}
                                error={!!errors?.confirm_password?.message}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                size="small"
                                label="Token"
                                {...register("reset_token", {
                                    required: {
                                        value: true,
                                        message: "Please enter token received in mail",
                                    },
                                })}
                                helperText={errors?.reset_token?.message}
                                error={!!errors?.reset_token?.message}
                            />
                        </Grid>
                        <Grid item xs={12} textAlign="center">
                            <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                                sx={{
                                    fontSize: { xs: 14, sm: 20 },
                                    borderRadius: 2,
                                    "& a": { textDecoration: "none", color: "text.light" },
                                }}
                                loading={submitting}
                            >
                                Submit
                            </Button>
                        </Grid>
                        <Grid item xs={12} textAlign="right">
                            <Typography.Text
                                color="primary"
                                sx={{
                                    "& > a": { color: "primary.dark" },
                                }}
                            >
                                Cancel? <Link to={"/login"}> Login</Link>
                            </Typography.Text>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
};

export default ResetPassword;
