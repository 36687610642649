import React, { useState } from "react";
import { Box, Grid, TextField, useTheme, componentSx, Typography, Button, IconButton } from "src/UILibrary";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { User } from "src/types";
import landingBG from "../../assets/image/grey-map.jpg";
import { ArrowBackIosNew } from "@mui/icons-material";
import { forgotPassword } from "src/modules/api/actions/auth";
import { failed, success } from "src/components/common/Toastify";

const ForgotPassword: React.FC = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const { MapContainerSx } = componentSx(theme);
    const [submitting, setSubmitting] = useState<boolean>(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<User>({
        mode: "all",
        defaultValues: {
            email: "",
        },
    });
    /* @Form Submit handler function*/
    const onSubmitHandler = async (dt: Partial<User>) => {
        try {
            setSubmitting(true);
            const result = await forgotPassword(dt);
            if (result?.data?.success === true) {
                success(result?.data?.message);
                navigate("/reset-password");
            } else {
                failed(result?.data?.error);
            }
        } catch (error: any) {
            failed(error?.response?.data?.errors?.message || error?.message);
        } finally {
            setSubmitting(false);
        }
    };

    const onSubmitErrorHandler = () => {
        console.log(errors);
    };

    return (
        <Box
            sx={{
                ...MapContainerSx,
                height: "100vh",
                backgroundImage: `url(${landingBG})`,
            }}
        >
            <Box
                sx={{
                    maxWidth: 480,
                    width: "100%",
                    mx: "auto",
                    p: 4,
                    bgcolor: "google.main",
                    borderRadius: 2,
                    boxShadow: "0 0 10px rgba(0,0,0,.3)",
                    position: "relative",
                }}
            >
                <IconButton onClick={() => navigate(-1)} sx={{ position: "absolute", top: 35 }} title="Back to login">
                    <ArrowBackIosNew />
                </IconButton>
                <Box component="form" onSubmit={handleSubmit(onSubmitHandler, onSubmitErrorHandler)}>
                    <Grid container rowSpacing={3} columnSpacing={3} justifyContent={"center"}>
                        <Grid item xs={12}>
                            <Typography.SubHeading color={"primary"} align="center">
                                Reset your password
                            </Typography.SubHeading>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography.Text color={"black"} align="center">
                                Enter your Freelancer.com email address so we can reset your password.
                            </Typography.Text>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                size="small"
                                label="Email"
                                {...register("email", {
                                    required: {
                                        value: true,
                                        message: "Email is required",
                                    },
                                    validate: {
                                        email: (v) => /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/.test(v) || "Please enter valid Email address",
                                    },
                                })}
                                helperText={errors?.email?.message}
                                error={!!errors?.email?.message}
                            />
                        </Grid>
                        <Grid item xs={12} textAlign="center">
                            <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                                sx={{
                                    fontSize: { xs: 14, sm: 20 },
                                    borderRadius: 2,
                                    "& a": { textDecoration: "none", color: "text.light" },
                                }}
                                loading={submitting}
                            >
                                Next
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
};

export default ForgotPassword;
