import { Theme } from "@mui/material/styles";

const componentSx = (theme: Theme) => {
  // Explore & Home Page Map Container
  const MapContainerSx = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundSize: "cover",
    backgroundPosition: "right bottom",
  };

  const MapAutocompleteHeaderInputSx = {
    bgcolor: theme.palette.background.textfield,
    opacity: 0.8,
    width: '100%',
    borderRadius: 20,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.primary.dark,

    "& input": { py: 1.4, color: theme.palette.text.primary, fontSize: 18 },
    "& fieldset": { border: "0 !important", outline: "none" },
  };

  const MapAutocompleteInputSx = {
    ...MapAutocompleteHeaderInputSx,
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
  };

  return {
    MapContainerSx,
    MapAutocompleteInputSx,
    MapAutocompleteHeaderInputSx,
  };
};

export { componentSx };
