import { axiosApiClient } from './../actions/apiClient';
interface Btype {
  business_type: string;
  business_area: string;
}
export const getBusinessTypes = () => {
    return axiosApiClient.get("defaults/get_business_types")
}

export const updateUserDefaults = (data : Btype) => {
    let newData = {
        "business_type": data.business_type,
        "location_address": data.business_area,
        "location_lat": -27.4773082,
        "location_lng": 153.0125446
    }
    return axiosApiClient.post("defaults/update_user_defaults", newData,  {
        withCredentials: true,
        headers: {
          "Access-Control-Allow-Origin": "http://localhost:3000"
        },
      })
}