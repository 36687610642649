import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

// import { useAuth } from "src/context/AuthContext";
import { RootState } from "src/redux/store/store";

export const ProtectedRoute: React.FC = () => {
    // const { user } = useAuth();
    const isLoggedIn = useSelector((state: RootState) => state?.auth?.isLoggedIn);

    if (isLoggedIn) {
        return <Outlet />;
    }

    return <Navigate to={`/login`} />;
};
