import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
    Avatar,
    Box,
    Button,
    TextField,
    InputAdornment,
    RoomOutlined,
    SearchOutlined,
    useTheme,
    componentSx,
    IconButton,
    Menu,
    MenuItem,
    ListItemIcon,
    LogoutIcon,
    PersonOutlineIcon,
} from "src/UILibrary";
import { getCoordinates } from "src/modules/api/lib/map";
import { AutocompleteProvider } from "src/modules/googlemap";
import LogoDesktop from "../../../assets/image/logo-desktop.png";
import { useJsApiLoader } from "@react-google-maps/api";
import { GOOGLE_API_LOADER_OPTIONS } from "src/constants/googlemap";
import { useAutocomplete } from "src/context/AutocompleteContext";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store/store";
import { userlogout } from "src/redux/slices/authService";
import { failed, success } from "src/components/common/Toastify";
import { reset_user_defaults, update_user_defaults } from "src/redux/slices/userDefaultsService";
import { reset_user_defaults_status, update_user_defaults_status } from "src/redux/slices/userDefaultsStatus";
import { userLogout } from "src/modules/api/actions/auth";
import Divider from "@mui/material/Divider";
import Select from "@mui/material/Select";
import { updateUserDefaults } from "src/modules/api/BusinessType";
import { InputLabel } from "@mui/material";
// import Option from "@mui/material/Option";
import { CircularProgress } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { useExplore } from "src/context/ExploreContext";

const Header: React.FC = () => {
    const { pathname } = useLocation();
    const theme = useTheme();
    const { MapAutocompleteHeaderInputSx } = componentSx(theme);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [isLoading, setIsLoading] = useState(false);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    /*-----------@For responsive header code-------------*/
    const [headerSearch, setHeaderSearch] = useState(false);
    const toggleSearch = () => {
        setHeaderSearch(true);
    };

    const navigate = useNavigate();
    const isLoggedIn = useSelector((state: RootState) => state?.auth?.isLoggedIn);
    const Dispatch = useDispatch();
    const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
    const [value, setValue] = useState("cafe");
    const { setCenterPos, setBoundaries } = useAutocomplete();
    const { setSelectedType } = useExplore();
    const { isLoaded } = useJsApiLoader(GOOGLE_API_LOADER_OPTIONS);
    /* ----------@handle Logo Image Click ------------ */
    const handleLogoClick = () => {
        if (isLoggedIn) {
            navigate("/explore");
            setSelectedType("avlproperty");
        } else {
            navigate("/");
        }
    };
    /* ----------@Autocomplete Searchbox------------ */
    const onAutocompleteLoad = (ac: google.maps.places.Autocomplete) => {
        ac.setComponentRestrictions({ country: ["aus"] });
        setAutocomplete(ac);
    };
    /* -----------@Autocomplete searchbox handler function----------- */
    const handlePlaceChanged = async () => {
        if (autocomplete !== null) {
            // console.log("Places :=========> ", autocomplete.getPlace());
            let lat = autocomplete.getPlace().geometry?.location?.lat() || 0;
            let lng = autocomplete.getPlace().geometry?.location?.lng() || 0;

            let formatted_address: string | undefined = "";
            let temp = autocomplete.getPlace().formatted_address;

            if (temp?.includes("St, ")) {
                formatted_address = temp?.split("St, ").pop();
            } else {
                formatted_address = temp?.split("Rd, ").pop();
            }
            setHeaderSearch(false); //For toggling searchbox in responsive design
            const borderCoordinates = await getCoordinates(formatted_address as string);

            setBoundaries(
                borderCoordinates
                    ?.filter((item: any) => item.geojson.type.toLowerCase() === "polygon")[0]
                    ?.geojson.coordinates[0]?.map((coordi: number[]) => ({
                        lng: coordi[0],
                        lat: coordi[1],
                    }))
            );

            setCenterPos({ lat, lng });
        } else {
            // console.warn("Autocomplete is not loaded yet!");
            // failed("Sorry, Autocomplete is not loaded yet!");
        }
    };

    const handleLogout = async () => {
        try {
            // setSubmitting(true);
            const result = await userLogout();
            if (result?.data?.success === true) {
                success(result?.data?.message);
                Dispatch(userlogout());
                Dispatch(reset_user_defaults());
                Dispatch(reset_user_defaults_status());
                // success("You have successfully loged out");
                navigate("/login");
            } else {
                failed(result?.data?.error);
            }
        } catch (error: any) {
            failed(error?.response?.data?.errors?.message || error?.message);
        } finally {
            // setSubmitting(false);
        }
    };
    const usersDefaultsData = useSelector((state: RootState) => state?.userDefaults?.userDefaultsData);

    const handleChange = async (e: any) => {
        setValue(e?.target?.value);
        try {
            setIsLoading(true);
            const data = {
                business_type: e?.target?.value,
                business_area: "West-End Brisbane",
            };
            const result = await updateUserDefaults(data);
            if (result?.data?.success === true) {
                // success(result?.data?.message || "Business defaults updated successfully");
                Dispatch(
                    update_user_defaults([
                        {
                            business_type: data?.business_type,
                            lat: -27.4773082,
                            lng: 153.0125446,
                            address: "West-End Brisbane",
                        },
                    ])
                );
                Dispatch(update_user_defaults_status());
            } else {
                failed(result?.data?.error);
            }
        } catch (error: any) {
            // Handle different types of errors
            if (error?.response?.data?.errors?.message) {
                failed(error?.response?.data?.errors?.message);
            } else if (error?.message) {
                failed(error?.message);
            } else {
                failed("An error occurred while updating business defaults");
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box sx={{ background: "#fff", width: "100%" }}>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    height: 70,
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    boxShadow: "0 10px 5px -10px #000000",
                }}
            >
                <Box sx={{ maxWidth: 230, "& img": { width: "100%", height: "60px" } }}>
                    {/* <Link to="" onClick={handleLogoClick}> */}
                    <img src={LogoDesktop} alt="SenseIQ Logo" onClick={handleLogoClick} style={{ cursor: "pointer" }} />
                    {/* </Link> */}
                </Box>
                <Box className="search-box-m">
                    <InputAdornment position="end">
                        <IconButton onClick={toggleSearch}>{!headerSearch ? <SearchOutlined color="primary" fontSize="medium" sx={{ height: "1.3em", width: "1.3em" }} /> : null}</IconButton>
                    </InputAdornment>
                </Box>
                <Box className={headerSearch ? "search-box active" : "search-box"}>
                    {pathname.slice(1) === "explore" && !!isLoaded && (
                        <>
                            <Box sx={{ display: "flex", gap: "40px" }}>
                                <Box sx={{ flex: 1 }}>
                                    <AutocompleteProvider
                                        onLoad={onAutocompleteLoad}
                                        onPlaceChanged={() => {
                                            handlePlaceChanged();
                                        }}
                                    >
                                        <TextField
                                            type="text"
                                            placeholder=""
                                            sx={{ ...MapAutocompleteHeaderInputSx, height: "45px", width: "450px" }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <RoomOutlined color="primary" sx={{ fontSize: 30 }} />
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() => {
                                                                setHeaderSearch(false);
                                                            }}
                                                        >
                                                            <SearchOutlined color="primary" fontSize="medium" />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </AutocompleteProvider>
                                </Box>
                                <Box display="flex" alignItems="center">
                                    {/* <InputLabel htmlFor="businessType" sx={{ textAlign: "center" }}>
                                        Business Type
                                    </InputLabel> */}
                                    <FormControl>
                                        <InputLabel id="input-business-type">Business Type</InputLabel>
                                        <Select
                                            labelId="input-business-type"
                                            label="Business Type"
                                            placeholder="Choose one…"
                                            size="small"
                                            value={usersDefaultsData[0]?.business_type ? usersDefaultsData[0]?.business_type : value}
                                            onChange={(e) => handleChange(e)}
                                            sx={{ minWidth: "120px" }}
                                            inputProps={{
                                                name: "businessType",
                                                id: "businessType",
                                            }}
                                            disabled={isLoading}
                                        >
                                            <MenuItem value={"Cafe"}>Cafe</MenuItem>
                                            <MenuItem value={"Bar"}>Bar</MenuItem>
                                            <MenuItem value={"Restaurant"}>Restaurant</MenuItem>
                                            <MenuItem value={"Florist"}>Florist</MenuItem>
                                            <MenuItem value={"Gym"}>Gym</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {isLoading && <CircularProgress size={20} style={{ marginLeft: 8 }} />}
                                </Box>
                            </Box>
                        </>
                    )}
                </Box>
                <Box sx={{ marginLeft: { xs: "unset", md: "auto" } }}>
                    {!isLoggedIn ? (
                        <Button
                            color="secondary"
                            variant="contained"
                            sx={{
                                fontSize: { xs: 14, sm: 20 },
                                borderRadius: 10,
                                "& a": { textDecoration: "none", color: "text.light" },
                            }}
                            onClick={() => {
                                navigate("/login");
                            }}
                        >
                            Login
                        </Button>
                    ) : (
                        <>
                            <IconButton
                                aria-controls={open ? "account-menu" : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                sx={{
                                    mr: "16px",
                                    fontSize: { xs: 14, sm: 20 },
                                    borderRadius: 10,
                                    bgcolor: (theme) => theme.palette.secondary.main,
                                    "& > svg": {
                                        fill: "white",
                                    },
                                    "&:hover": { bgcolor: "secondary.main" },
                                }}
                                onClick={handleClick}
                            >
                                <PersonOutlineIcon />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={open}
                                onClose={handleClose}
                                onClick={handleClose}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: "visible",
                                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                        mt: 1.5,
                                        "& .MuiAvatar-root": {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        "&:before": {
                                            content: '""',
                                            display: "block",
                                            position: "absolute",
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: "background.paper",
                                            transform: "translateY(-50%) rotate(45deg)",
                                            zIndex: 0,
                                        },
                                    },
                                }}
                                transformOrigin={{ horizontal: "right", vertical: "top" }}
                                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                            >
                                <MenuItem onClick={() => navigate("/profile")}>
                                    <Avatar /> Profile
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={handleLogout}>
                                    <ListItemIcon>
                                        <LogoutIcon fontSize="small" />
                                    </ListItemIcon>
                                    Logout
                                </MenuItem>
                            </Menu>
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default Header;
