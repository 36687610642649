import { axiosApiClient, } from "../actions/apiClient"
import axios from "axios"
import { IFavouriteSite } from "src/modules/googlemap/Constants"

/*----------@Api call for getting favourites List---------- */
export const getSavedLocationsList = () =>{
    return axiosApiClient.get(`defaults/get_fav_sites_list`,{
        withCredentials: true,
        headers: {
          "Access-Control-Allow-Origin": "http://localhost:3000"
        },
      })
}

/*----------@Api call for deleting  single favourites from favourites List---------- */
export const deleteFavouriteSite = (siteid:number|undefined) =>{
    return axiosApiClient.delete(`defaults/delete_fav_site/${siteid}`,{
        withCredentials: true,
        headers: {
          "Access-Control-Allow-Origin": "http://localhost:3000"
        },
      })
}

/*----------@Api call for getting formatted address---------- */
export const getAddress = (lat:number, lng:number) =>{
  return axios.get(` https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`)
}

/*----------@Api call for getting score for particular location---------- */
export const getLocationScore = async (lat:number, lng:number, address:string) =>{
  let newData = {
    "location_lat": lat,
    "location_lng": lng,
    "location_address": address
  }
  return await axiosApiClient.post("defaults/get_location_score", newData, {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "http://localhost:3000"
      },
    })
}

/*----------@Api call for adding favourites to favourites List---------- */
export const addFavourite = async (data:IFavouriteSite) =>{
  let newData = {
    "business_type": data?.business_type,
    "location_address": data?.location_address,
    "location_lat": data?.location_lat,
    "location_lng": data?.location_lng,
    "location_score": data?.score
  }
  return await axiosApiClient.post("defaults/add_fav_site", newData, {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "http://localhost:3000"
      },
    })
}

/*----------@Api call for getting location properties---------- */
export const getAvailableProperties = async () =>{
  return await axiosApiClient.get("defaults/get_properties/West-End", {
      headers: {
        "Access-Control-Allow-Origin": "http://localhost:3000"
      },
    })
}

/*----------@Api call for getting location businesses---------- */
export const getLocalBusinesses = async (type:string) =>{
  return await axiosApiClient.get(`defaults/get_location_businesses/${type}`, {
      headers: {
        "Access-Control-Allow-Origin": "http://localhost:3000"
      },
    })
}