import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "src/modules/ThemeProvider";
import Home from "src/pages/Home";
import Explore from "src/pages/Explore";
import Login from "src/pages/Auth/Login";
import Register from "src/pages/Auth/Register";
import ForgotPassword from "src/pages/Auth/ForgotPassword";
import { ProtectedRoute } from "src/routes/ProtectedRoute";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./redux/store/store";
import { ToastContainer } from "react-toastify";
import ResetPassword from "./pages/Auth/ResetPassword";
import Profile from "./pages/Profile/Profile";
import { AutocompleteContextProvider } from "./context/AutocompleteContext";
import { ExploreContextProvider } from "./context/ExploreContext";
import { ListContextProvider } from "./context/ListContext";

function App() {
    return (
        <ThemeProvider>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}></PersistGate>
                {/* <AuthProvider> */}
                <AutocompleteContextProvider>
                    <ExploreContextProvider>
                        <ListContextProvider>
                            <BrowserRouter>
                                <Routes>
                                    <Route path="/" element={<Home />} />
                                    <Route path="/" element={<ProtectedRoute />}>
                                        <Route path="/explore" element={<Explore />} />
                                        <Route path="/profile" element={<Profile />} />
                                    </Route>
                                    <Route path="/login" element={<Login />} />
                                    <Route path="/register" element={<Register />} />
                                    <Route path="/forgot-password" element={<ForgotPassword />} />
                                    <Route path="/reset-password" element={<ResetPassword />} />
                                    <Route path="/*" element={<Home />} />
                                </Routes>
                                <ToastContainer />
                            </BrowserRouter>
                        </ListContextProvider>
                    </ExploreContextProvider>
                </AutocompleteContextProvider>
                {/* </AuthProvider> */}
            </Provider>
        </ThemeProvider>
    );
}

export default App;
