import React from "react";
import { useNavigate } from "react-router-dom";
import Layouts from "../../layouts";
import { Box, Button, Typography, useTheme, componentSx } from "src/UILibrary";
import landingBG from "../../assets/image/grey-map.jpg";

const Home: React.FC = () => {
    const theme = useTheme();
    const { MapContainerSx } = componentSx(theme);
    const navigate = useNavigate();
    return (
        <>
            <Layouts>
                <Box
                    sx={{
                        ...MapContainerSx,
                        height: "calc(100vh - 70px)",
                        backgroundImage: `url(${landingBG})`,
                    }}
                >
                    <Typography.Large
                        sx={{
                            color: "primary.main",
                            textAlign: "center",
                            maxWidth: 700,
                            zIndex: 2,
                        }}
                    >
                        Find a great location for your business
                    </Typography.Large>
                    <Box sx={{ mt: 5 }}>
                        <Button
                            color="secondary"
                            variant="contained"
                            size="large"
                            sx={{
                                fontSize: { xs: 20, sm: 30 },
                                px: 8,
                                py: 2,
                                borderRadius: 4,
                                "& a": { textDecoration: "none", color: "text.light" },
                            }}
                            onClick={() => {
                                navigate("/explore");
                            }}
                        >
                            Explore
                        </Button>
                    </Box>
                </Box>
            </Layouts>
        </>
    );
};

export default Home;
