import React, { useContext, useState, useMemo } from "react";
// import { TCenterPos } from "src/types";

interface IAutocompleteContext {
    boundaries: google.maps.LatLngLiteral[];
    setBoundaries: (value: google.maps.LatLngLiteral[]) => void;
    centerPos: google.maps.LatLng | google.maps.LatLngLiteral | undefined;
    setCenterPos: (value: google.maps.LatLng | google.maps.LatLngLiteral | undefined) => void;
}

interface IAutocompleteContextProvider {
    children: React.ReactNode;
}

const AutocompleteContext = React.createContext<IAutocompleteContext | null>(null);

const AutocompleteContextProvider: React.FC<IAutocompleteContextProvider> = ({ children }) => {
    const [boundaries, setBoundaries] = useState<google.maps.LatLngLiteral[]>([]);
    const [centerPos, setCenterPos] = useState<google.maps.LatLng | google.maps.LatLngLiteral | undefined>();

    const value = useMemo(() => ({ boundaries, setBoundaries, centerPos, setCenterPos }), [boundaries, setBoundaries, centerPos, setCenterPos]);

    return <AutocompleteContext.Provider value={value}>{children}</AutocompleteContext.Provider>;
};

function useAutocomplete() {
    const context = useContext(AutocompleteContext) as IAutocompleteContext;
    if (context === undefined) {
        throw new Error("useAutocomplete must be used within a AutocompleteProvider");
    }

    return context;
}

export { AutocompleteContextProvider, useAutocomplete };

/*-----------------@State Usage Information-------------- */
/* This context is used for autocompletion in google search bar
    centerPos: State which is used for storing lat and lng position of main explore marker.

    boundaries: State which is used for drawing polygonal boundary beside main explore 
                marker when we search location from searchbar in header.
*/
