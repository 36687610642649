import axios from "axios";
// axios.defaults.withCredentials = true;

export const axiosOsmClient = axios.create({
  baseURL: process.env.REACT_APP_OSM_API_URL,
});

export const axiosMapsClient = axios.create({
  baseURL: process.env.REACT_APP_GOOGLE_MAP_API_URL,
});

export const axiosApiClient = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL,
  // withCredentials : true,
});