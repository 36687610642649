import React, { useEffect, useRef, useState } from "react";
import { Container, Typography, Box } from "src/UILibrary";
import { Link } from "react-router-dom";
import ConfirmDialogBox from "src/components/ConfirmDialogBox";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useExplore } from "src/context/ExploreContext";
import { Card, CardContent, CardActions } from "@mui/material";
import CustomSpinner from "src/components/common/CustomSpinner";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DetailsDrawer from "src/modules/googlemap/DetailsDrawer";
import { useAutocomplete } from "src/context/AutocompleteContext";

export interface FavouritesProps {
    favouriteList: any;
    deleteFavouriteSiteFromList: (siteid: number) => Promise<void>;
    setShowInfoWindow: React.Dispatch<React.SetStateAction<boolean>>;
}

const SavedLocations: React.FC<FavouritesProps> = ({ favouriteList, deleteFavouriteSiteFromList, setShowInfoWindow }) => {
    const [confirmOpen, setConfirmOpen] = React.useState(false);
    const [deletingId, setDeletingId] = React.useState<number>(-1);
    const selectedItemRef = useRef<HTMLDivElement>(null); //userRef for scrolling into associated item in the list when cliked on the marker on map
    const { selectedLocation, setSelectedLocation, isLoadingFlag, setInfoWndPos } = useExplore();
    const { setCenterPos, setBoundaries } = useAutocomplete();
    /*------@Handler functions for opening view details drawer--------- */
    const [openDrawer, setOpenDrawer] = useState(false);
    const handleOpenDrawer = (list: any) => {
        setOpenDrawer(true);
    };
    const handleCloseDrawer = () => {
        setOpenDrawer(false);
        //setInfoWndPos(null);
    };
    /*------@Handler functions to open infowindow for clicked on location in saved locations card in list --------- */
    const handleFavouriteAddress = (lat: number, lng: number, score: number, siteid: number) => {
        setSelectedLocation({ lat: lat, lng: lng, filterType: "favourites", favPosition: true, favSiteScore: score, siteid: siteid });
        setShowInfoWindow(true); //To show save locations infowindow
        setBoundaries([]); // Clear Ploygonal boundary around main marker on map
        setCenterPos(undefined); // Clear main marker on the map
        setInfoWndPos(null); // Close main marker infowindow on the map
    };
    /*-----------@useEffect hook for scrolling into selected item in the list----------- */
    useEffect(() => {
        if (selectedItemRef?.current) {
            selectedItemRef?.current?.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "start",
            });
        }
    }, [selectedLocation]);
    return (
        <>
            {/*------@Calling View Details Drawer--------*/}
            <DetailsDrawer openDrawer={openDrawer} handleCloseDrawer={handleCloseDrawer} />
            {confirmOpen ? (
                <ConfirmDialogBox
                    title="Delete Saved Locations?"
                    message="Are you sure, you want to remove this from saved locations?"
                    openDialog={confirmOpen}
                    setOpenDialog={setConfirmOpen}
                    onConfirm={deleteFavouriteSiteFromList}
                    deletingId={deletingId}
                ></ConfirmDialogBox>
            ) : null}
            <Container maxWidth="xl">
                <Box>
                    <Box>
                        <Typography.SubHeading gutterBottom color={"entry"} sx={{ color: "#009dee", fontWeight: "normal", fontSize: { xs: "1.2rem", md: "1.5rem" } }} align="center">
                            Saved Locations
                        </Typography.SubHeading>
                    </Box>

                    {isLoadingFlag ? (
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "400px" }}>
                            <CustomSpinner />
                        </Box>
                    ) : (
                        <Box className="list-details">
                            {favouriteList !== null ? (
                                favouriteList?.map((list: any, index: number) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            width: "95%",
                                            mt: 2,
                                            border: selectedLocation && selectedLocation?.siteid === list?.siteid ? "2px solid rgba(0,0,0,0.7)" : "none",
                                            borderRadius: selectedLocation && selectedLocation?.siteid === list?.siteid ? "6px" : "none",
                                        }}
                                        ref={selectedLocation && selectedLocation?.siteid === list?.siteid ? selectedItemRef : null}
                                    >
                                        <Card sx={{ background: "rgba(211,211,211,0.3)", width: "100%" }}>
                                            <CardContent sx={{ pb: "0px" }}>
                                                <Box sx={{ display: "flex" }}>
                                                    <Box
                                                        className="fav-percentage above"
                                                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "65px", height: "65px" }}
                                                    >
                                                        <span style={{ fontWeight: "bold" }}>Score</span>
                                                        {list.score}
                                                    </Box>
                                                    <Box sx={{ flex: 1 }}>
                                                        <Box sx={{ p: "0px 16px 6px 10px" }}>
                                                            <Typography.DetailsSubHeading sx={{ color: "#000", fontWeight: 500 }}>{list?.business_type}</Typography.DetailsSubHeading>
                                                        </Box>
                                                        <Box>
                                                            <Link
                                                                to=""
                                                                style={{ textDecoration: "none" }}
                                                                onClick={() => {
                                                                    handleFavouriteAddress(list?.lat, list?.lng, list?.score, list?.siteid);
                                                                }}
                                                            >
                                                                <Typography.Text
                                                                    color={"primary"}
                                                                    sx={{
                                                                        display: "flex",
                                                                        color: "rgba(0,0,0,0.8)",
                                                                        fontWeight: 400,
                                                                        p: "0px 20px 0px 10px",
                                                                        letterSpacing: "0.3px",
                                                                        width: "250px",
                                                                        fontSize: "0.9rem",
                                                                        wordWrap: "normal",
                                                                    }}
                                                                >
                                                                    <LocationOnIcon sx={{ pr: "2px" }} />
                                                                    {list?.address}
                                                                </Typography.Text>
                                                            </Link>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </CardContent>
                                            <CardActions
                                                disableSpacing
                                                sx={{
                                                    alignSelf: "stretch",
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    alignItems: "flex-start",
                                                    p: "0 10",
                                                }}
                                            >
                                                <Tooltip title="View Details">
                                                    <IconButton
                                                        aria-label="settings"
                                                        onClick={() => {
                                                            handleOpenDrawer(list);
                                                            // navigate("/site-details");
                                                        }}
                                                        style={{ color: "#009dee" }}
                                                    >
                                                        <VisibilityIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Delete">
                                                    <IconButton
                                                        aria-label="settings"
                                                        onClick={() => {
                                                            setDeletingId(list?.siteid);
                                                            setConfirmOpen(true);
                                                            // setDeleting(list?.siteid);
                                                        }}
                                                        style={{ color: "rgba(256,0,0,0.7" }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </CardActions>
                                        </Card>
                                    </Box>
                                ))
                            ) : (
                                <>
                                    <Typography.DetailsSubHeading align="center">You haven't saved any locations yet</Typography.DetailsSubHeading>
                                </>
                            )}
                        </Box>
                    )}
                </Box>
            </Container>
        </>
    );
};

export default SavedLocations;
