import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "src/UILibrary";
import { useJsApiLoader, GoogleMap, MarkerF, PolygonF, InfoWindowF } from "@react-google-maps/api";
import { getMapPlaceData } from "src/modules/api/lib/map";
import { useAutocomplete } from "src/context/AutocompleteContext";
import "./LoadMapProvider.css";
// Constants
import { GOOGLE_API_LOADER_OPTIONS, GOOGLE_MAP_API_OPTIONS, POLYGON_OPTIONS } from "src/constants/googlemap";
import { failed } from "src/components/common/Toastify";
import { getAddress, getLocationScore } from "../api/GoogleMapsData";
// import { listenerCount } from "process";
import { Button, Stack } from "@mui/material";
import FavouritesMarkerIcon from "../../assets/image/icon-marker-2.png";
import SenseiqMarkerIcon from "../../assets/image/logo-mobile.png";
import AvialbaleProperties from "../../assets/image/pin.png";
import DetailsDrawer from "./DetailsDrawer";
import { LoadMapProviderProps } from "./Constants";
import VisibilityIcon from "@mui/icons-material/Visibility";
import StarIcon from "@mui/icons-material/Star";
import { useExplore } from "src/context/ExploreContext";
import CustomSpinner from "../../components/common/CustomSpinner";
import { useList } from "src/context/ListContext";

const LoadMapProvider: React.FC<LoadMapProviderProps> = ({ locationProperties, favouriteList, handleAddFavourite, isMainLoading }) => {
    const { isLoaded, loadError } = useJsApiLoader(GOOGLE_API_LOADER_OPTIONS);
    const [mapInstance, setMapInstance] = useState<google.maps.Map | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    /*----@Code (states) for Markers and InfoWindow from contexts------- */
    const { centerPos, boundaries, setCenterPos, setBoundaries } = useAutocomplete();
    const {
        isLoadingFlag,
        locationScoreData,
        setLocationScoreData,
        selectedType,
        selectedLocation,
        setSelectedLocation,
        showInfoWindow,
        setShowInfoWindow,
        businessType,
        showDetailInfowindow,
        setShowDetailInfowindow,
        infoWndPos,
        setInfoWndPos,
    } = useExplore();
    const { existingBusinessesList } = useList();
    const [initialCenter, setInitialCenter] = useState<google.maps.LatLng | google.maps.LatLngLiteral | undefined>();
    const [openDrawer, setOpenDrawer] = useState(false);
    /*------@Filtering array for available property and existing businesses-------*/
    const filteredAvlProperty = locationProperties?.filter((list: any) => list?.property_id === selectedLocation?.propertyId);
    const filteredExistBusinesses = existingBusinessesList?.[0]?.filter((list: any) => list?.google_id === selectedLocation?.existBusinessId);
    const filteredFavouriteList = favouriteList?.filter((list: any) => list?.siteid === selectedLocation?.siteid);
    /*--------@Initial useEffect to set centerpos and boundaries to null values------ */
    useEffect(() => {
        setInitialCenter({
            lat: -27.4773082,
            lng: 153.0125446,
        });
        setCenterPos(undefined);
        setBoundaries([]);
        setShowDetailInfowindow({ infoType: "", mainMarkerFlag: false, avlPropertyFlag: false, localBusinessFlag: false });
        setInfoWndPos(null);
    }, []);

    const handleGoogleMapLoad = (mapInstance: google.maps.Map) => {
        setMapInstance(mapInstance);
    };
    /*-------@Handler function for main marker clicked on google map---------*/
    const handleMarkerClick = (e: google.maps.MapMouseEvent) => {
        // console.log(e.latLng?.lat(), e.latLng?.lng());
        setShowDetailInfowindow({ infoType: "", mainMarkerFlag: true, avlPropertyFlag: false, localBusinessFlag: false });
        if (e) {
            setInfoWndPos({
                lat: e.latLng?.lat() as number,
                lng: e.latLng?.lng() as number,
            });
        }
        getFormattedAddress(e?.latLng?.lat() as number, e?.latLng?.lng() as number);
        handleCloseDrawer();
    };

    /*-------@Api call for getting location score--------*/
    const getScore = async (lat: number, lng: number, address: string) => {
        try {
            const { data } = await getLocationScore(lat, lng, address);
            if (data?.success === true) {
                setLocationScoreData({
                    business_type: data?.data?.business_type,
                    location_lat: data?.data?.lat,
                    location_lng: data?.data?.lng,
                    location_address: data?.data?.address,
                    score: data?.data?.score || 0,
                });
            } else {
                failed(data?.data?.error);
            }
        } catch (error: any) {
            failed(error?.response?.data?.errors?.message || error?.message);
        }
    };

    /*-------@Api call for getting formatted address by sending lat and lng--------*/
    const getFormattedAddress = async (lat: number, lng: number) => {
        setLocationScoreData(undefined);
        try {
            setIsLoading(true);
            const { data } = await getAddress(lat, lng);
            if (data?.status === "OK") {
                await getScore(lat, lng, data?.results?.[0]?.formatted_address);
            } else {
                failed(data?.data?.error);
            }
        } catch (error: any) {
            failed(error?.response?.data?.errors?.message || error?.message);
        } finally {
            setIsLoading(false);
        }
    };

    /* ------@Handler function when clicked on any location in google maps---------- */
    const handleGoogleMapClick = async (evt: any) => {
        // console.log(evt?.latLng?.lat(), evt?.latLng?.lng(), evt?.placeId);
        let data = "";
        if (evt?.placeId) {
            data = await getMapPlaceData(evt?.placeId);
        }
        setCenterPos({ lat: evt?.latLng?.lat(), lng: evt?.latLng?.lng() });
        // getFormattedAddress(evt?.latLng?.lat(), evt?.latLng?.lng());
        setInfoWndPos(null); //for placing infowindow on correct marker position
        setSelectedLocation({ lat: 0, lng: 0, filterType: "" });
        setShowInfoWindow(false);
    };
    /*-----@Infowindow Close Handler Function---------- */
    const handleInfoWndClose = () => {
        setShowDetailInfowindow({ infoType: "", mainMarkerFlag: false, avlPropertyFlag: false, localBusinessFlag: false });
        setInfoWndPos(null);
        setBoundaries([]);
        handleCloseDrawer();
        setShowInfoWindow(false);
    };
    /*------@Handler functions for opening view ddetails drawer--------- */
    const handleOpenDrawer = () => {
        setOpenDrawer(true);
    };
    const handleCloseDrawer = () => {
        setOpenDrawer(false);
        // setInfoWndPos(null);
    };
    return (
        <Box className="lmp-main-container">
            {!!loadError && <Box>Map cannot be loaded right now, sorry!</Box>}
            {!isMainLoading ? (
                <>
                    {!!isLoaded && !loadError ? (
                        <GoogleMap
                            mapContainerStyle={{ width: "100%", height: "100%" }}
                            options={GOOGLE_MAP_API_OPTIONS}
                            center={
                                {
                                    lat: -27.4773082,
                                    lng: 153.0125446,
                                } || initialCenter
                            }
                            zoom={15}
                            onClick={handleGoogleMapClick}
                            onLoad={handleGoogleMapLoad}
                            clickableIcons={false}
                            onCenterChanged={() => {
                                setInitialCenter(centerPos);
                            }}
                        >
                            <PolygonF paths={boundaries} options={POLYGON_OPTIONS} />
                            {/*-------------Main Marker-------------- */}
                            {centerPos && <MarkerF position={centerPos} onClick={handleMarkerClick} icon={{ url: SenseiqMarkerIcon, scaledSize: new google.maps.Size(34, 44) }}></MarkerF>}
                            {/*-------------Main Marker Infowindow-----------*/}
                            {infoWndPos && (
                                <InfoWindowF position={infoWndPos} onCloseClick={handleInfoWndClose} options={{ pixelOffset: new window.google.maps.Size(0, -40) }}>
                                    <>
                                        {showDetailInfowindow?.mainMarkerFlag ? (
                                            <Box sx={{ p: "2px" }}>
                                                {showDetailInfowindow?.infoType === "mainMarkerDetails" ? (
                                                    <Box>
                                                        {isLoading || isLoadingFlag ? (
                                                            <Box className="lmp-main-marker-spinner">
                                                                <CustomSpinner />
                                                            </Box>
                                                        ) : (
                                                            <Box>
                                                                <Box className="flex-common" sx={{ mb: "4px" }}>
                                                                    <Typography.Text className="lmp-infowindow-address">{locationScoreData?.location_address}</Typography.Text>
                                                                </Box>
                                                                <Box display="flex" justifyContent="space-between" className="property-infowindow-box" sx={{ mb: "6px", mt: "0px" }}>
                                                                    <Typography.Text className="property-typo-heading">SenseIQ Score</Typography.Text>
                                                                    <Typography.Text className="font-weight-bold">
                                                                        {locationScoreData?.score !== 0 ? locationScoreData?.score : "No Score Avaialable"}
                                                                    </Typography.Text>
                                                                </Box>
                                                                <Stack direction="column" spacing={1} justifyContent="center" alignItems="center">
                                                                    {locationScoreData?.score !== 0 ? (
                                                                        <Button
                                                                            variant="contained"
                                                                            size="small"
                                                                            className="fav-percentage above fav-info-btn btn-text"
                                                                            onClick={handleOpenDrawer}
                                                                            sx={{ width: "165px" }}
                                                                        >
                                                                            View Details
                                                                            <VisibilityIcon sx={{ pl: "6px" }} />
                                                                        </Button>
                                                                    ) : null}
                                                                    {locationScoreData?.score !== 0 ? (
                                                                        <Button
                                                                            variant="contained"
                                                                            size="small"
                                                                            className="fav-percentage above fav-info-btn"
                                                                            onClick={() => {
                                                                                handleAddFavourite(locationScoreData);
                                                                            }}
                                                                            sx={{ width: "165px" }}
                                                                        >
                                                                            Save
                                                                            <StarIcon sx={{ pl: "6px" }} />
                                                                        </Button>
                                                                    ) : null}
                                                                </Stack>
                                                            </Box>
                                                        )}
                                                    </Box>
                                                ) : (
                                                    <Box>
                                                        {isLoading ? (
                                                            <Box className="lmp-main-popup-spinner">
                                                                <CustomSpinner />
                                                            </Box>
                                                        ) : (
                                                            <>
                                                                <Box className="flex-common" sx={{ mb: "4px" }}>
                                                                    <Typography.Text className="lmp-infowindow-address">{locationScoreData?.location_address}</Typography.Text>
                                                                </Box>
                                                                <Button
                                                                    variant="contained"
                                                                    size="small"
                                                                    className="fav-percentage above fav-info-btn"
                                                                    sx={{ width: "168px" }}
                                                                    onClick={() => {
                                                                        // getFormattedAddress(infoWndPos?.lat as number, infoWndPos?.lng as number);
                                                                        setShowDetailInfowindow({
                                                                            infoType: "mainMarkerDetails",
                                                                            mainMarkerFlag: true,
                                                                            avlPropertyFlag: false,
                                                                            localBusinessFlag: false,
                                                                        });
                                                                    }}
                                                                >
                                                                    SenseIQ Score
                                                                </Button>
                                                            </>
                                                        )}
                                                    </Box>
                                                )}
                                            </Box>
                                        ) : null}
                                    </>
                                </InfoWindowF>
                            )}
                            {/*-----------@Calling View Details Drawer------------*/}
                            <DetailsDrawer openDrawer={openDrawer} handleCloseDrawer={handleCloseDrawer} />
                            {/*----------@Favourites Markers and infowindow------------*/}
                            {!!favouriteList?.length
                                ? favouriteList?.map((marker: any, index: number) => (
                                      <MarkerF
                                          position={{ lat: marker?.lat, lng: marker?.lng }}
                                          key={index}
                                          onClick={(e) => {
                                              setShowInfoWindow(true);
                                              setSelectedLocation({ ...selectedLocation, lat: marker?.lat, lng: marker?.lng, filterType: "favourites", favPosition: false, siteid: marker?.siteid });
                                              setInfoWndPos(null);
                                              setCenterPos(undefined);
                                          }}
                                          icon={{ url: FavouritesMarkerIcon, scaledSize: new google.maps.Size(54, 54) }}
                                      ></MarkerF>
                                  ))
                                : null}
                            {showInfoWindow && selectedLocation?.filterType === "favourites" ? (
                                <InfoWindowF
                                    position={{
                                        lat: selectedLocation?.lat,
                                        lng: selectedLocation?.lng,
                                    }}
                                    onCloseClick={() => {
                                        setShowInfoWindow(false);
                                        setInfoWndPos(null);
                                    }}
                                    options={{ pixelOffset: new window.google.maps.Size(0, -40) }} //For placing infowindow position offset
                                >
                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                                        <Box className="flex-common">
                                            <Typography.Text className="lmp-infowindow-address">{filteredFavouriteList[0]?.address}</Typography.Text>
                                        </Box>
                                        <Box display="flex" justifyContent="space-between" className="property-infowindow-box" sx={{ width: "160px" }}>
                                            <Typography.Text className="property-typo-heading">SenseIQ Score</Typography.Text>
                                            <Typography.Text className="font-weight-bold">{filteredFavouriteList[0]?.score || selectedLocation?.favSiteScore}</Typography.Text>
                                        </Box>
                                        <Button variant="contained" size="small" className="fav-percentage above fav-info-btn" onClick={handleOpenDrawer} sx={{ width: "160px" }}>
                                            View Details
                                            <VisibilityIcon sx={{ pl: "6px" }} />
                                        </Button>
                                    </Box>
                                </InfoWindowF>
                            ) : null}
                            {/*------------@For Available Properties Markers & infowindow-----------*/}
                            {selectedType === "avlproperty"
                                ? locationProperties?.length &&
                                  locationProperties?.map((marker: any, index: any) => (
                                      <MarkerF
                                          icon={{ url: AvialbaleProperties, scaledSize: new google.maps.Size(44, 44) }}
                                          position={{ lat: marker?.lat, lng: marker?.lng }}
                                          key={index}
                                          onClick={(e) => {
                                              setSelectedLocation({ ...selectedLocation, lat: marker?.lat, lng: marker?.lng, filterType: "avlproperty", propertyId: marker?.property_id });
                                              setShowDetailInfowindow({ infoType: "", mainMarkerFlag: false, avlPropertyFlag: true, localBusinessFlag: false });
                                              setCenterPos(undefined);
                                              setInfoWndPos(null);
                                              setBoundaries([]);
                                          }}
                                      ></MarkerF>
                                  ))
                                : null}
                            {selectedType === "avlproperty" && selectedLocation?.filterType === "avlproperty"
                                ? selectedLocation?.lat !== 0 && (
                                      <InfoWindowF
                                          position={{ lat: selectedLocation?.lat, lng: selectedLocation?.lng }}
                                          onCloseClick={() => {
                                              setSelectedLocation({ ...selectedLocation, filterType: "" });
                                              setShowDetailInfowindow({ infoType: "", mainMarkerFlag: false, avlPropertyFlag: false, localBusinessFlag: false });
                                          }}
                                          options={{ pixelOffset: new window.google.maps.Size(0, -40) }}
                                      >
                                          <>
                                              {showDetailInfowindow?.avlPropertyFlag ? (
                                                  <Box sx={{ p: "2px" }}>
                                                      {showDetailInfowindow?.infoType === "avlPropertyDetails" ? (
                                                          <>
                                                              {isLoading || isLoadingFlag ? (
                                                                  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "165px", width: "200px" }}>
                                                                      <CustomSpinner />
                                                                  </Box>
                                                              ) : (
                                                                  <>
                                                                      <Box className="flex-common">
                                                                          <Typography.Text sx={{ width: "200px", fontSize: "14px", color: "#000" }} className="lmp-css-ellipsis ">
                                                                              {filteredAvlProperty[0]?.address}
                                                                          </Typography.Text>
                                                                      </Box>
                                                                      <Box display="flex" justifyContent="space-between" className="property-infowindow-box" sx={{ mb: "8px" }}>
                                                                          <Typography.Text className="property-typo-heading">SenseIQ Score</Typography.Text>

                                                                          <Typography.Text className="font-weight-bold">
                                                                              {locationScoreData?.score !== 0 ? locationScoreData?.score : "No Score Avaialable"}
                                                                          </Typography.Text>
                                                                      </Box>
                                                                      <Box display="flex" justifyContent="space-between" className="property-infowindow-box">
                                                                          <Typography.Text className="property-typo-heading">Business Type</Typography.Text>
                                                                          <Typography.Text className="font-weight-bold">{locationScoreData?.business_type}</Typography.Text>
                                                                      </Box>
                                                                      <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                                                                          {locationScoreData?.score !== 0 ? (
                                                                              <Button
                                                                                  variant="contained"
                                                                                  size="small"
                                                                                  className="fav-percentage above fav-info-btn"
                                                                                  onClick={handleOpenDrawer}
                                                                                  sx={{ width: "110px" }}
                                                                              >
                                                                                  View Details
                                                                                  <VisibilityIcon sx={{ pl: "6px" }} />
                                                                              </Button>
                                                                          ) : null}
                                                                          {locationScoreData?.score !== 0 ? (
                                                                              <Button
                                                                                  variant="contained"
                                                                                  size="small"
                                                                                  className="fav-percentage above fav-info-btn"
                                                                                  onClick={() => {
                                                                                      handleAddFavourite(locationScoreData);
                                                                                  }}
                                                                                  sx={{ width: "110px" }}
                                                                              >
                                                                                  Save
                                                                                  <StarIcon sx={{ pl: "6px" }} />
                                                                              </Button>
                                                                          ) : null}
                                                                      </Stack>
                                                                  </>
                                                              )}
                                                          </>
                                                      ) : (
                                                          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                                                              <Box className="flex-common">
                                                                  <Typography.Text className="lmp-infowindow-address" sx={{ mb: "6px" }}>
                                                                      {filteredAvlProperty[0]?.address}
                                                                  </Typography.Text>
                                                              </Box>
                                                              <Button
                                                                  variant="contained"
                                                                  size="small"
                                                                  className="fav-percentage above fav-info-btn"
                                                                  sx={{ width: "160px" }}
                                                                  onClick={() => {
                                                                      getFormattedAddress(selectedLocation?.lat, selectedLocation?.lng);
                                                                      setShowDetailInfowindow({
                                                                          infoType: "avlPropertyDetails",
                                                                          mainMarkerFlag: false,
                                                                          avlPropertyFlag: true,
                                                                          localBusinessFlag: false,
                                                                      });
                                                                  }}
                                                              >
                                                                  SenseIq Score
                                                              </Button>
                                                          </Box>
                                                      )}
                                                  </Box>
                                              ) : null}
                                          </>
                                      </InfoWindowF>
                                  )
                                : null}
                            {/*------------@For Existing Businesses Markers & infowindow------------*/}
                            {selectedType === "existbusiness"
                                ? businessType !== "" &&
                                  existingBusinessesList?.length &&
                                  existingBusinessesList?.[0]?.map((marker: any, index: any) => (
                                      <MarkerF
                                          icon={{ url: AvialbaleProperties, scaledSize: new google.maps.Size(44, 44) }}
                                          position={{ lat: marker?.lat, lng: marker?.lng }}
                                          key={index}
                                          onClick={(e) => {
                                              setSelectedLocation({ ...selectedLocation, lat: marker?.lat, lng: marker?.lng, filterType: "existbusiness", existBusinessId: marker?.google_id });
                                              setShowDetailInfowindow({ infoType: "", mainMarkerFlag: false, avlPropertyFlag: true, localBusinessFlag: true });
                                              setCenterPos(undefined);
                                              setBoundaries([]);
                                              setInfoWndPos(null);
                                              setInfoWndPos(null);
                                          }}
                                      ></MarkerF>
                                  ))
                                : null}
                            {selectedType === "existbusiness" && selectedLocation?.filterType === "existbusiness"
                                ? selectedLocation?.lat !== 0 && (
                                      <InfoWindowF
                                          position={{ lat: selectedLocation?.lat, lng: selectedLocation?.lng }}
                                          onCloseClick={() => {
                                              setSelectedLocation({ ...selectedLocation, filterType: "" });
                                          }}
                                          options={{ pixelOffset: new window.google.maps.Size(0, -40) }}
                                      >
                                          <>
                                              {showDetailInfowindow?.localBusinessFlag ? (
                                                  <Box sx={{ p: "2px" }}>
                                                      {showDetailInfowindow?.infoType === "localBusinessDetails" ? (
                                                          <>
                                                              {isLoading || isLoadingFlag ? (
                                                                  <Box className="lmp-local-business-spinner">
                                                                      <CustomSpinner />
                                                                  </Box>
                                                              ) : (
                                                                  <>
                                                                      <Box>
                                                                          <Typography.Text sx={{ width: "220px" }} className="font-weight-bold lmp-local-business-name lmp-css-ellipsis">
                                                                              {filteredExistBusinesses?.[0]?.business_name}
                                                                          </Typography.Text>
                                                                      </Box>
                                                                      <Box>
                                                                          <Typography.Text sx={{ fontSize: "14px", width: "220px", color: "#000" }} className="lmp-css-ellipsis">
                                                                              {filteredExistBusinesses?.[0]?.vicinity}
                                                                          </Typography.Text>
                                                                      </Box>
                                                                      <Box display="flex" justifyContent="space-between" className="property-infowindow-box" sx={{ mb: "8px" }}>
                                                                          <Typography.Text className="property-typo-heading">SenseIQ Score</Typography.Text>

                                                                          <Typography.Text className="font-weight-bold">
                                                                              {locationScoreData?.score !== 0 ? locationScoreData?.score : "No Score Avaialable"}
                                                                          </Typography.Text>
                                                                      </Box>
                                                                      <Box display="flex" justifyContent="space-between" className="property-infowindow-box">
                                                                          <Typography.Text className="property-typo-heading">Business Type</Typography.Text>
                                                                          <Typography.Text className="font-weight-bold">
                                                                              {filteredExistBusinesses?.[0]?.business_type
                                                                                  ? filteredExistBusinesses?.[0]?.business_type?.charAt(0).toUpperCase() +
                                                                                    filteredExistBusinesses?.[0]?.business_type?.slice(1)
                                                                                  : "Not Available"}
                                                                          </Typography.Text>
                                                                      </Box>
                                                                      <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                                                                          {locationScoreData?.score !== 0 ? (
                                                                              <Button
                                                                                  variant="contained"
                                                                                  size="small"
                                                                                  className="fav-percentage above fav-info-btn"
                                                                                  onClick={handleOpenDrawer}
                                                                                  sx={{ width: "110px" }}
                                                                              >
                                                                                  View Details
                                                                                  <VisibilityIcon sx={{ pl: "6px" }} />
                                                                              </Button>
                                                                          ) : null}
                                                                          {locationScoreData?.score !== 0 ? (
                                                                              <Button
                                                                                  variant="contained"
                                                                                  size="small"
                                                                                  className="fav-percentage above fav-info-btn"
                                                                                  onClick={() => {
                                                                                      handleAddFavourite(locationScoreData);
                                                                                  }}
                                                                                  sx={{ width: "110px" }}
                                                                              >
                                                                                  Save
                                                                                  <StarIcon sx={{ pl: "6px" }} />
                                                                              </Button>
                                                                          ) : null}
                                                                      </Stack>
                                                                  </>
                                                              )}
                                                          </>
                                                      ) : (
                                                          <Box className="lmp-flex-dir-col">
                                                                <>
                                                                    <Box fontWeight='fontWeightMedium'>
                                                                        <Typography.Text sx={{ mb: "8px"  }} className="lmp-infowindow-address">
                                                                            <strong>{filteredExistBusinesses?.[0]?.business_name}</strong>
                                                                        </Typography.Text>
                                                                    </Box>
                                                                    <Box className="flex-common">
                                                                        <Typography.Text className="lmp-infowindow-address" sx={{ mb: "6px" }}>
                                                                            {filteredExistBusinesses?.[0]?.vicinity}
                                                                        </Typography.Text>
                                                                    </Box>
                                                              </>
                                                              <Button
                                                                  variant="contained"
                                                                  size="small"
                                                                  className="fav-percentage above fav-info-btn"
                                                                  sx={{ width: "160px" }}
                                                                  onClick={() => {
                                                                      getFormattedAddress(selectedLocation?.lat, selectedLocation?.lng);
                                                                      setShowDetailInfowindow({
                                                                          infoType: "localBusinessDetails",
                                                                          mainMarkerFlag: false,
                                                                          avlPropertyFlag: false,
                                                                          localBusinessFlag: true,
                                                                      });
                                                                  }}
                                                              >
                                                                  SenseIq Score
                                                              </Button>
                                                          </Box>
                                                      )}
                                                  </Box>
                                              ) : null}
                                          </>
                                      </InfoWindowF>
                                  )
                                : null}
                        </GoogleMap>
                    ) : (
                        <CustomSpinner />
                    )}
                </>
            ) : (
                // <CircularProgress size={80} thickness={3} />
                <CustomSpinner />
            )}
        </Box>
    );
};
export { LoadMapProvider };
