import React, { useContext, useState } from "react";

export interface IListContext {
    existingBusinessesList: any;
    setExistingBusinessesList: React.Dispatch<any>;
}

interface IListContextProvider {
    children: React.ReactNode;
}

const ListContext = React.createContext<IListContext | null>(null);

const ListContextProvider: React.FC<IListContextProvider> = ({ children }) => {
    const [existingBusinessesList, setExistingBusinessesList] = useState<any>();
    const value = { existingBusinessesList, setExistingBusinessesList };

    return <ListContext.Provider value={value}>{children}</ListContext.Provider>;
};

function useList() {
    const context = useContext(ListContext) as IListContext;
    if (context === undefined) {
        throw new Error("useList must be used within a ListContextProvider");
    }

    return context;
}

export { ListContextProvider, useList };
