import React from "react";
import { Button, Dialog, DialogContent, DialogTitle, DialogActions } from "src/UILibrary";

interface Props {
    title: string;
    openDialog: boolean;
    setOpenDialog: (value: any) => void;
    onConfirm: (id: number) => Promise<void>;
    message: string;
    deletingId: number;
}
const ConfirmDialogBox: React.FC<Props> = (props) => {
    const { title, message, openDialog, setOpenDialog, onConfirm, deletingId } = props;
    return (
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)} aria-labelledby="confirm-dialog">
            <DialogTitle id="confirm-dialog">{title}</DialogTitle>
            <DialogContent>{message}</DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={() => setOpenDialog(false)} color="secondary">
                    No
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        onConfirm(deletingId);
                        setOpenDialog(false);
                    }}
                >
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialogBox;
