import React from "react";
import { Typography as MuiTypography, TypographyProps } from "@mui/material";

const TypographyComponentWithStyles = (defaultStyles: TypographyProps) => {
    const TextComponent = ({ children, ...rest }: TypographyProps) => {
        return (
            <MuiTypography {...defaultStyles} {...rest}>
                {children}
            </MuiTypography>
        );
    };

    return TextComponent;
};

export const Large = TypographyComponentWithStyles({
    fontSize: { xs: "2rem", md: "4rem" },
    lineHeight: { xs: "2.25rem", md: "4.5rem" },
    fontWeight: 900,
});

export const Heading = TypographyComponentWithStyles({
    fontSize: { xs: "1.75rem", md: "3rem" },
    lineHeight: { xs: "2rem", md: "4rem" },
    fontWeight: 800,
});

export const SubHeading = TypographyComponentWithStyles({
    fontSize: { xs: "1.5rem", md: "2rem" },
    lineHeight: { xs: "2rem", md: "2.8rem" },
    fontWeight: 800,
});

export const Text = TypographyComponentWithStyles({
    fontSize: { xs: ".75rem", md: ".9rem" },
    lineHeight: { xs: "1rem", md: "1rem" },
    fontWeight: 400,
});

export const SubLink = TypographyComponentWithStyles({
    fontSize: { xs: "1rem", md: "1.2rem" },
    lineHeight: { xs: "2rem", md: "2.8rem" },
    fontWeight: 600,
});

export const DetailsHeading = TypographyComponentWithStyles({
    fontSize: { xs: "1.2rem", md: "1.5rem" },
    lineHeight: { xs: "2rem", md: "2.8rem" },
    fontWeight: 700,
});

export const DetailsSubHeading = TypographyComponentWithStyles({
    fontSize: { xs: "1rem", md: "1.2rem" },
    lineHeight: { xs: "1.4rem", md: "1.4rem" },
    fontWeight: 500,
});
