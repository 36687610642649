import { createSlice } from "@reduxjs/toolkit";
// import { User } from "src/types";
// import { RootState } from "../store/store";

interface UserDefaultStatusType {
    isUserDefaultsSet: boolean   
}
const initialState: UserDefaultStatusType =  {
    isUserDefaultsSet: false  
  
}

export const userDefaultStatusSlice = createSlice({
    name: "userDefaultStatus",
    initialState,
    reducers: {
        set_user_defaults_status : (state) => {
            state.isUserDefaultsSet = true;
        },
        update_user_defaults_status(state) {
            state.isUserDefaultsSet = true;
        },
        reset_user_defaults_status : (state) => {
            state.isUserDefaultsSet = false;
        },
    }
});
    
  export const { set_user_defaults_status, update_user_defaults_status, reset_user_defaults_status } = userDefaultStatusSlice.actions;
  
  export default userDefaultStatusSlice.reducer;

