import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore, } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import authService from "../slices/authService";
import userDefaultsService from "../slices/userDefaultsService";
import userDefaultsStatus from "../slices/userDefaultsStatus";

const persistConfig = {
  key: "root",
  storage,
};

const reducers = combineReducers({auth: authService, userDefaults: userDefaultsService, userDefaultsStatus: userDefaultsStatus });

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export let persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;