import React from "react";
import { Box, componentSx } from "src/UILibrary";
import Header from "src/components/partials/Header";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store/store";
import SidebarMenu from "src/pages/Explore/SidebarMenu";

interface LayoutProps {
    children: React.ReactNode;
}

const Layouts: React.FC<LayoutProps> = ({ children }) => {
    const theme = useTheme();
    const { MapContainerSx } = componentSx(theme);
    const isLoggedIn = useSelector((state: RootState) => state?.auth?.isLoggedIn);
    return (
        <Box>
            {isLoggedIn ? (
                <SidebarMenu>
                    <>{children}</>
                </SidebarMenu>
            ) : (
                <>
                    <Header />
                    {children}
                </>
            )}
        </Box>
    );
};

export default Layouts;
