import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
import { failed, success } from "src/components/common/Toastify";
import { updateProfile, userProfile } from "src/modules/api/Profile";
// import { RootState } from "src/redux/store/store";
import { useForm } from "react-hook-form";
import { Typography, Avatar, Box, Button, TextField, Grid, Container, Fab, InputAdornment, Visibility, VisibilityOff, IconButton } from "src/UILibrary";
import AddIcon from "@mui/icons-material/Add";
import Layouts from "src/layouts";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema, ProfileData, IChangePassword, cpwdSchema } from "./Constants";
import { resetPassword } from "src/modules/api/actions/auth";
import Divider from "@mui/material/Divider";
import CustomSpinner from "src/components/common/CustomSpinner";
import { useExplore } from "src/context/ExploreContext";

const Profile: React.FC = () => {
    const [userProfileData, setUserProfileData] = useState<Partial<ProfileData>>({ given_name: "", family_name: "", picture: "", email: "" });
    const [fileSelected, setFileSelected] = useState(false);
    const [currentFile, setCurrentFile] = useState<File>();
    const [previewImage, setPreviewImage] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
    const { setSelectedType } = useExplore();
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<ProfileData>({
        mode: "all",
        resolver: yupResolver(schema),
        // defaultValues: {
        //     email: "",
        //     given_name: "",
        //     family_name: "",
        //     picture: "",
        //     profileImage: undefined,
        // },
    });
    const {
        register: registerCpwd,
        handleSubmit: handleSubmitCpwd,
        setValue: setValueCpwd,
        reset,
        formState: { errors: errorsCpwd },
    } = useForm<IChangePassword>({
        mode: "all",
        resolver: yupResolver(cpwdSchema),
    });
    /*-----------@Extracting onChange method to create custom onChange handler to preview image---------*/
    const { onChange, ...params } = register("profileImage");
    const handlePictureChange = (e: React.SyntheticEvent<EventTarget>) => {
        // const formInputImage = (e.target as HTMLFormElement).files[0];
        setFileSelected(true);
        setCurrentFile((e.target as HTMLFormElement).files[0]);
        setPreviewImage(URL.createObjectURL((e.target as HTMLFormElement).files[0]));
    };
    /*------------@Form Submit handler function for user profile data----------*/
    const onSubmitHandler = async (dt: ProfileData) => {
        // console.log(dt);
        try {
            setIsLoading(true);
            setSubmitting(true);
            const result = await updateProfile(dt, previewImage);
            if (result?.data?.success === true) {
                success(result?.data?.message);
            } else {
                failed(result?.data?.error);
            }
        } catch (error: any) {
            failed(error?.response?.data?.errors?.message || error?.message);
        } finally {
            setSubmitting(false);
            setIsLoading(false);
            // reset({ password: "", confirm_password: "" });
        }
    };
    const onSubmitErrorHandler = () => {
        console.log(errors);
    };
    /*-----------@Form Submit handler function for change password---------*/
    const onCpwdHandleSubmit = async (dt: IChangePassword) => {
        // console.log(dt);
        try {
            setIsLoading(true);
            setSubmitting(true);
            const result = await resetPassword(dt);
            if (result?.data?.success === true) {
                success(result?.data?.message);
            } else {
                failed(result?.data?.error);
            }
        } catch (error: any) {
            failed(error?.response?.data?.errors?.message || error?.message);
        } finally {
            setSubmitting(false);
            setIsLoading(false);
            reset({ password: "", confirm_password: "" });
        }
    };
    /* @Api call for getting user profile Data(Name,Email...) */
    const getProfile = async () => {
        try {
            setIsLoading(true);
            const result = await userProfile();
            if (result?.data?.success === true) {
                setUserProfileData(result?.data?.data?.[0]);
                setValue("given_name", result?.data?.data?.[0].given_name);
                setValue("family_name", result?.data?.data?.[0].family_name);
                setValue("email", result?.data?.data?.[0].email);
            } else {
                failed(result?.data?.error);
            }
        } catch (error: any) {
            failed(error?.response?.data?.errors?.message || error?.message);
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        getProfile();
        setSelectedType("");
        return () => {};
    }, []);

    return (
        <>
            <Layouts>
                <Box
                    sx={{
                        width: "100%",
                        display: { md: "flex" },
                    }}
                >
                    {isLoading ? (
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh" }}>
                            {/* <CircularProgress size={80} thickness={4} /> */}
                            <CustomSpinner />
                        </Box>
                    ) : (
                        <>
                            <Container maxWidth="lg">
                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Box
                                        sx={{
                                            maxWidth: 430,
                                            mt: 5,
                                            // width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Box component="form" onSubmit={handleSubmit(onSubmitHandler, onSubmitErrorHandler)}>
                                            <Grid container rowSpacing={3} columnSpacing={3}>
                                                <Grid item xs={10}>
                                                    <Typography.SubHeading color={"primary"} align="left">
                                                        User Profile
                                                    </Typography.SubHeading>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "5px" }}>
                                                        <Avatar
                                                            alt="Profile Image"
                                                            src={fileSelected ? previewImage : userProfileData?.picture ? userProfileData?.picture : undefined}
                                                            sx={{ height: "90px", width: "90px" }}
                                                        />
                                                        <label htmlFor="profileImage">
                                                            <input
                                                                {...params}
                                                                style={{ display: "none" }}
                                                                id="profileImage"
                                                                type="file"
                                                                accept="image/png, image/jpeg, image/jpg"
                                                                onChange={(event) => {
                                                                    handlePictureChange(event);
                                                                    onChange(event); // calling onChange returned from register
                                                                }}
                                                            />
                                                            <Fab color="primary" size="small" component="span" aria-label="add">
                                                                <AddIcon />
                                                            </Fab>
                                                        </label>
                                                        {errors.profileImage && <p className="error">{errors.profileImage.message}</p>}
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <TextField
                                                        fullWidth
                                                        size="small"
                                                        InputLabelProps={{ shrink: true }}
                                                        defaultValue={userProfileData?.given_name ? userProfileData?.given_name : ""}
                                                        label="First Name"
                                                        {...register("given_name")}
                                                        helperText={errors?.given_name?.message}
                                                        error={!!errors?.given_name?.message}
                                                    />
                                                </Grid>

                                                <Grid item xs={10}>
                                                    <TextField
                                                        fullWidth
                                                        size="small"
                                                        label="Last Name"
                                                        InputLabelProps={{ shrink: true }}
                                                        defaultValue={userProfileData?.family_name ? userProfileData?.family_name : ""}
                                                        {...register("family_name")}
                                                        helperText={errors?.family_name?.message}
                                                        error={!!errors?.family_name?.message}
                                                    />
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <TextField
                                                        fullWidth
                                                        size="small"
                                                        label="Email"
                                                        disabled
                                                        defaultValue={userProfileData?.email ? userProfileData?.email : ""}
                                                        InputLabelProps={{ shrink: true }}
                                                        {...register("email")}
                                                    />
                                                </Grid>
                                                <Grid item xs={10} textAlign="left">
                                                    <Button
                                                        color="secondary"
                                                        variant="contained"
                                                        type="submit"
                                                        sx={{
                                                            marginBottom: "30px",
                                                            fontSize: { xs: 14, sm: 20 },
                                                            borderRadius: 2,
                                                            "& a": { textDecoration: "none", color: "text.light" },
                                                        }}
                                                        // loading={submitting}
                                                    >
                                                        Save
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Divider orientation="vertical" />
                                    </Box>
                                </Box>
                            </Container>
                            <Box sx={{ height: { md: "70vh" }, mt: { md: "30px" } }}>
                                <Divider orientation="vertical" />
                            </Box>
                            <Container maxWidth="lg">
                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Box
                                        sx={{
                                            maxWidth: 430,
                                            // mt: { md: "70px", xs: "25px" },
                                            // width: "100%",
                                            mt: 5,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Box component="form" onSubmit={handleSubmitCpwd(onCpwdHandleSubmit)}>
                                            <Grid container rowSpacing={3} columnSpacing={3}>
                                                <Grid item xs={10} sx={{ mb: { md: "70px", xs: "25px" } }}>
                                                    <Typography.SubHeading color={"primary"} align="left">
                                                        Change Password
                                                    </Typography.SubHeading>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <TextField
                                                        fullWidth
                                                        size="small"
                                                        InputLabelProps={{ shrink: true }}
                                                        type={showPassword ? "text" : "password"}
                                                        label="Password"
                                                        InputProps={{
                                                            autoComplete: "off",
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton aria-label="toggle password visibility" onClick={() => setShowPassword(!showPassword)}>
                                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        {...registerCpwd("password")}
                                                        helperText={errorsCpwd?.password?.message}
                                                        error={!!errorsCpwd?.password?.message}
                                                    />
                                                </Grid>

                                                <Grid item xs={10}>
                                                    <TextField
                                                        fullWidth
                                                        size="small"
                                                        label="Confirm Password"
                                                        type={showConfirmPassword ? "text" : "password"}
                                                        InputLabelProps={{ shrink: true }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton aria-label="toggle password visibility" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                                                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        {...registerCpwd("confirm_password")}
                                                        helperText={errorsCpwd?.confirm_password?.message}
                                                        error={!!errorsCpwd?.confirm_password?.message}
                                                    />
                                                </Grid>
                                                <Grid item xs={10} textAlign="left">
                                                    <Button
                                                        color="secondary"
                                                        variant="contained"
                                                        type="submit"
                                                        sx={{
                                                            marginBottom: "30px",
                                                            fontSize: { xs: 14, sm: 20 },
                                                            borderRadius: 2,
                                                            mt: { md: "60px" },
                                                            "& a": { textDecoration: "none", color: "text.light" },
                                                        }}
                                                        // loading={submitting}
                                                    >
                                                        Save
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                </Box>
                            </Container>
                        </>
                    )}
                </Box>
            </Layouts>
        </>
    );
};

export default Profile;
