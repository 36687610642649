import React, { useContext, useState } from "react";
import { IFavouriteSite } from "src/modules/googlemap/Constants";
import { ISelectedLocation } from "src/pages/Explore/Constants";
import { IDetailInfowindow } from "./Constants";
// import { TCenterPos } from "src/types";

export interface IExploreContext {
    isLoadingFlag: boolean;
    setIsLoadingFlag: React.Dispatch<React.SetStateAction<boolean>>;
    locationScoreData: IFavouriteSite | undefined;
    setLocationScoreData: React.Dispatch<React.SetStateAction<IFavouriteSite | undefined>>;
    selectedType: string;
    setSelectedType: React.Dispatch<React.SetStateAction<string>>;
    selectedLocation: ISelectedLocation;
    setSelectedLocation: React.Dispatch<React.SetStateAction<ISelectedLocation>>;
    showInfoWindow: boolean;
    setShowInfoWindow: React.Dispatch<React.SetStateAction<boolean>>;
    businessType: string;
    setBusinessType: React.Dispatch<React.SetStateAction<string>>;
    showDetailInfowindow: IDetailInfowindow;
    setShowDetailInfowindow: React.Dispatch<React.SetStateAction<IDetailInfowindow>>;
    infoWndPos: google.maps.LatLng | google.maps.LatLngLiteral | null | undefined;
    setInfoWndPos: React.Dispatch<React.SetStateAction<google.maps.LatLng | google.maps.LatLngLiteral | null | undefined>>;
}

interface IExploreContextProvider {
    children: React.ReactNode;
}

const ExploreContext = React.createContext<IExploreContext | null>(null);

const ExploreContextProvider: React.FC<IExploreContextProvider> = ({ children }) => {
    const [isLoadingFlag, setIsLoadingFlag] = React.useState<boolean>(false);
    const [locationScoreData, setLocationScoreData] = useState<IFavouriteSite | undefined>({ business_type: "", location_lat: 0, location_lng: 0, location_address: "", score: 0 });
    const [selectedType, setSelectedType] = useState<string>("avlproperty");
    const [selectedLocation, setSelectedLocation] = useState<ISelectedLocation>({ lat: 0, lng: 0, filterType: "" });
    const [showInfoWindow, setShowInfoWindow] = useState<boolean>(false);
    const [businessType, setBusinessType] = React.useState("");
    const [showDetailInfowindow, setShowDetailInfowindow] = useState<IDetailInfowindow>({ infoType: "", mainMarkerFlag: false, avlPropertyFlag: false, localBusinessFlag: false });
    const [infoWndPos, setInfoWndPos] = useState<google.maps.LatLng | google.maps.LatLngLiteral | undefined | null>();
    const value = {
        isLoadingFlag,
        setIsLoadingFlag,
        locationScoreData,
        setLocationScoreData,
        selectedType,
        setSelectedType,
        selectedLocation,
        setSelectedLocation,
        showInfoWindow,
        setShowInfoWindow,
        businessType,
        setBusinessType,
        showDetailInfowindow,
        setShowDetailInfowindow,
        infoWndPos,
        setInfoWndPos,
    };

    return <ExploreContext.Provider value={value}>{children}</ExploreContext.Provider>;
};

function useExplore() {
    const context = useContext(ExploreContext) as IExploreContext;
    if (context === undefined) {
        throw new Error("useExplore must be used within a ExploreContextProvider");
    }

    return context;
}

export { ExploreContextProvider, useExplore };

/*-----------------@State Usage Information-------------- */
/*  This context is used for storing various states related to google map rendering and listing
    of various lists.

    isLoadingFlag: Flag for loading spinner while performing api calls

    selectedType: String type which will have value - 1)avlproperty 2)favourites 3)existbusiness
                  To toggle between menu on the sidebar. Depending upon value show particular list

    showInfoWindow: Boolean flag to show Saved Location Infowindow on map

    businessType: To set the Local Businesse type for fetching particular business api data

    infoWndPos: sets the lat and lng value for the main explore marker infowindow on google map

    showDetailInfowindow: Object which conains value as  
                            infoType: string; //value can be 1)mainMarkerDetails 2)avlPropertyDetails 3)localBusinessDetails
                            mainMarkerFlag:boolean; 
                            avlPropertyFlag: boolean;
                            localBusinessFlag: boolean
                            This is used for the functinality of 2 pop ups infowindow on map
                            1)Pop up InfoWindow for showing button with SensenIQ Score
                            2)Pop up InfoWindow for showing details and save button

    locationScoreData: object which stores information when we call api getScore

    selectedLocation: Object which contains
                        lat: number;
                        lng: number;
                        filterType: string;
                        siteid?: number;
                        propertyId?: number;
                        favPosition?: boolean; 
                        existBusinessId?: string;
                        favSiteScore?: number;
                        This is used for storing lat and lng values for 
                        1)saved location
                        2)Available properties 
                        3)Local Businesses
                        Marker and infowindow
                        This is the main object which performs functionality related to selecting
                            associated item in the list and showing associated infowindow for that item on the map.

*/
