import * as React from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Header from "src/components/partials/Header";
import { useAutocomplete } from "../../context/AutocompleteContext";
import { useExplore } from "../../context/ExploreContext";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import StorefrontIcon from "@mui/icons-material/Storefront";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import { useList } from "src/context/ListContext";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
    background: "#084c80",
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    background: "#084c80",
    width: "theme.spacing(7)",
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    boxShadow: "0 10px 5px -10px #000000",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    minHeight: "0px !important",
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    background: "rgba(0,157,238,0.8)",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

interface LayoutProps {
    children: React.ReactNode;
}

export const SidebarMenu: React.FC<LayoutProps> = ({ children }) => {
    // const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const { setCenterPos, setBoundaries } = useAutocomplete();
    const { setExistingBusinessesList } = useList();
    const { selectedType, setSelectedType, setSelectedLocation, setShowInfoWindow, setBusinessType, setInfoWndPos, setShowDetailInfowindow } = useExplore();
    const navigate = useNavigate();
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    {open ? (
                        <Box sx={{ height: "70px", width: "60px", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                            <IconButton onClick={handleDrawerClose} sx={{ color: "#fff" }}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </Box>
                    ) : (
                        <Box sx={{ height: "70px", width: "60px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <IconButton onClick={handleDrawerOpen} sx={{ color: "#fff" }}>
                                <MenuIcon />
                            </IconButton>
                        </Box>
                    )}
                </DrawerHeader>

                <Divider />
                <List>
                    <ListItem disablePadding sx={{ display: "block", background: selectedType === "avlproperty" ? "rgba(211,211,211,1)" : "none" }}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",
                                px: 2.5,
                                color: selectedType === "avlproperty" ? "#000" : "#fff",
                            }}
                            onClick={() => {
                                setBusinessType(""); //For clearing local businesses
                                setShowInfoWindow(false); //For closing saved location infowindow
                                navigate("/explore");
                                setSelectedType("avlproperty"); //to navigate to available properties
                                setExistingBusinessesList([]);
                                setSelectedLocation({ lat: 0, lng: 0, filterType: "avlproperty" }); // For showing available properties markers and infowindow
                                setCenterPos(undefined); //For clearing main marker from the map
                                setInfoWndPos(null); // For closing main marker infowindow
                                setBoundaries([]); // For cleaing polygonal boundary from map
                                setShowDetailInfowindow({ infoType: "", mainMarkerFlag: false, avlPropertyFlag: false, localBusinessFlag: false }); // To implement two step up pop up infowindow
                            }}
                        >
                            <Tooltip title="Available Properties">
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : "auto",
                                        justifyContent: "center",
                                        color: selectedType === "avlproperty" ? "#000" : "#fff",
                                    }}
                                >
                                    <LocationCityIcon />
                                </ListItemIcon>
                            </Tooltip>
                            <ListItemText primary="Available Properties" sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding sx={{ display: "block", background: selectedType === "favourites" ? "rgba(211,211,211,1)" : "none" }}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",
                                px: 2.5,
                                color: selectedType === "favourites" ? "#000" : "#fff",
                            }}
                            onClick={() => {
                                setBusinessType("");
                                setShowInfoWindow(false);
                                setSelectedType("favourites");
                                navigate("/explore");
                                setExistingBusinessesList([]);
                                setSelectedLocation({ lat: 0, lng: 0, filterType: "favourites" });
                                setCenterPos(undefined);
                                setInfoWndPos(null);
                                setBoundaries([]);
                                setShowDetailInfowindow({ infoType: "", mainMarkerFlag: false, avlPropertyFlag: false, localBusinessFlag: false });
                            }}
                        >
                            <Tooltip title="Saved Locations">
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : "auto",
                                        justifyContent: "center",
                                        color: selectedType === "favourites" ? "#000" : "#fff",
                                    }}
                                >
                                    <StarIcon />
                                </ListItemIcon>
                            </Tooltip>
                            <ListItemText primary="Saved Locations" sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding sx={{ display: "block", background: selectedType === "existbusiness" ? "rgba(211,211,211,1)" : "none" }}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",
                                px: 2.5,
                                color: selectedType === "existbusiness" ? "#000" : "#fff",
                            }}
                            onClick={() => {
                                setSelectedType("existbusiness");
                                navigate("/explore");
                                setInfoWndPos(null);
                                setCenterPos(undefined);
                                setBoundaries([]);
                                setShowInfoWindow(false);
                                setShowDetailInfowindow({ infoType: "", mainMarkerFlag: false, avlPropertyFlag: false, localBusinessFlag: false });
                            }}
                        >
                            <Tooltip title="Local Businesses">
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : "auto",
                                        justifyContent: "center",
                                        color: selectedType === "existbusiness" ? "#000" : "#fff",
                                    }}
                                >
                                    <StorefrontIcon />
                                </ListItemIcon>
                            </Tooltip>
                            <ListItemText primary="Local Businesses" sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1 }}>
                <Header />
                <DrawerHeader />
                {children}
            </Box>
        </Box>
    );
};

export default SidebarMenu;
