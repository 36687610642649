import React from "react";
import { Box, Grid } from "src/UILibrary";
import { IListProps } from "./Constants";
import { useExplore } from "src/context/ExploreContext";
import LocalBusinesses from "./LocalBusinesses";
import AvailableProperties from "./AvailableProperties";
import SavedLocations from "../Favourite/SavedLocations";

const MapList: React.FC<IListProps> = ({ locationProperties, favouriteList, deleteFavouriteSiteFromList, isAvlLoading }) => {
    const { selectedType, setShowInfoWindow } = useExplore();
    return (
        <Box className="list-container">
            <Grid container>
                <Grid item xs={12}>
                    {selectedType === "avlproperty" ? <AvailableProperties locationProperties={locationProperties} isAvlLoading={isAvlLoading} /> : null}
                    {selectedType === "favourites" ? (
                        <SavedLocations favouriteList={favouriteList} deleteFavouriteSiteFromList={deleteFavouriteSiteFromList} setShowInfoWindow={setShowInfoWindow} />
                    ) : null}
                    {selectedType === "existbusiness" ? <LocalBusinesses /> : null}
                </Grid>
            </Grid>
        </Box>
    );
};

export default MapList;
