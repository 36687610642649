import { createTheme, PaletteMode } from "src/UILibrary";

declare module "@mui/material/styles" {
  interface PaletteOptions {
    third: PaletteOptions["primary"];
    area: PaletteOptions["primary"];
    google: PaletteOptions["primary"];
    line: PaletteOptions["primary"];
    entry: PaletteOptions["primary"];
    chip: PaletteOptions["primary"];
    custom: PaletteOptions["primary"];
  }
  interface TypeBackground {
    bar: TypeBackground["default"];
    colorPanel: TypeBackground["default"];
    body: TypeBackground["default"];
    section: TypeBackground["default"];
    textfield: TypeBackground["default"];
    heading: TypeBackground["default"];
    custom: TypeBackground["default"];
  }
  interface TypeText {
    third: TypeText["primary"];
    forth: TypeText["primary"];
    custom: TypeText["primary"];
    help: TypeText["primary"];
    light: TypeText["primary"];
  }
}

export const getTheme = (mode: PaletteMode) =>
  createTheme({
    palette: {
      mode,
      background: {
        default: mode === "light" ? "#FFFFFF" : "#444341",
        paper: mode === "light" ? "#F4EDE7" : "#585755",
        bar: mode === "light" ? "#F49021" : "#444341",
        colorPanel: mode === "light" ? "#F4EDE7" : "#FFFFFF",
        body: mode === "light" ? "#FFFBF6" : "#444341",
        section: mode === "light" ? "#857465" : "#FFFFFF",
        textfield: mode === "light" ? "#FFFFFF" : "transparent",
        heading: mode === "light" ? "#F49021" : "#6B6B69",
        custom: mode === "light" ? "#FFFFFF" : "#6B6B69",
      },
      primary: {
        main: "#084C80",
        dark: "#084C80",
        contrastText: "#FFFFFF",
      },
      secondary: {
        main: mode === "light" ? "#F49021" : "#084C80",
        dark: mode === "light" ? "#F49021" : "#F49021",
        contrastText: "#FFFFFF",
      },
      third: {
        main: mode === "light" ? "#084C80" : "#FFFFFF",
        dark: mode === "light" ? "F58A27" : "#F0F0F0",
        contrastText: mode === "light" ? "#FFFFFF" : "#585755",
      },
      area: {
        main: mode === "light" ? "#857465" : "#6B6B69",
        dark: mode === "light" ? "#756557" : "#595957",
      },
      google: {
        main: "#FFFFFF",
        dark: "#F0F0F0",
        contrastText: "#5F5B55",
      },
      line: {
        main: "#06C755",
        dark: "#04BD4F",
        contrastText: "#FFFFFF",
      },
      entry: {
        main: mode === "light" ? "#B9AB9E" : "#6B6B69",
        dark: mode === "light" ? "#A6978A" : "#5C5C59",
        contrastText: "#FFFFFF",
      },
      chip: {
        main: "transparent",
        dark: "transparent",
        contrastText: mode === "light" ? "#444341" : "#FFFFFF",
      },
      custom: {
        main: mode === "light" ? "#97CE75" : "#FFFFFF",
        dark: mode === "light" ? "#7EC3D9" : "#FFFFFF",
      },
      text: {
        primary: mode === "light" ? "#084C80" : "#FFFFFF",
        secondary: mode === "light" ? "#084C80" : "#084C80",
        third: mode === "light" ? "#97CE75" : "#FFFFFF",
        forth: mode === "light" ? "#7EC3D9" : "#FFFFFF",
        help: mode === "light" ? "#6B6B69" : "#FFFFFF",
        light: "#ffffff",
        custom: "#F49021",
        disabled: "#C5C5C5",
      },
      common: {
        black: "#444341",
      },
    },
    typography: {
      fontFamily: ['"Lato"', "sans-serif"].join(","),
    },
  });
