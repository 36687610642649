import React, { useEffect, useRef } from "react";
import { Box, Typography, InputLabel, MenuItem, FormControl, Select } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Rating from "@mui/material/Rating";
import { Link } from "react-router-dom";
// import Tooltip from "@mui/material/Tooltip";
import { useExplore } from "src/context/ExploreContext";
import CustomSpinner from "src/components/common/CustomSpinner";
import { useAutocomplete } from "src/context/AutocompleteContext";
import Divider from "@mui/material/Divider";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useList } from "src/context/ListContext";

// interface IExistingProps {
//     isLoadingFlag: boolean;
// }
const LocalBusinesses: React.FC = () => {
    const { selectedLocation, setSelectedLocation, businessType, setBusinessType, setShowInfoWindow, isLoadingFlag, setShowDetailInfowindow, setInfoWndPos } = useExplore();
    const { setCenterPos, setBoundaries } = useAutocomplete();
    const { existingBusinessesList } = useList();
    /*------@Handler function for setting selected location--------- */
    const handleLocationAddress = (lat: number, lng: number, id: string) => {
        setSelectedLocation({ lat: lat, lng: lng, filterType: "existbusiness", existBusinessId: id });
        setShowDetailInfowindow({ infoType: "", mainMarkerFlag: false, avlPropertyFlag: false, localBusinessFlag: true });
        setBoundaries([]); // Clear Ploygonal boundary around main marker on map
        setCenterPos(undefined); // Clear main marker on the map
        setInfoWndPos(null); // Close main marker infowindow on the map
    };
    const selectedItemRef = useRef<HTMLDivElement>(null); //userRef for scrolling into associated item in the list when cliked on the marker on map
    /*-----------@useEffect hook for scrolling into selected item in the list with dependency selectedLocation----------- */
    useEffect(() => {
        if (selectedItemRef?.current) {
            selectedItemRef?.current?.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "start",
            });
        }
    }, [selectedLocation]);

    return (
        <>
            <Box sx={{ position: "sticky" }}>
                <FormControl className="list-formcontrol" size="small">
                    <InputLabel
                        id="type"
                        sx={{
                            "&.Mui-focused": {
                                color: "084c80",
                            },
                            background: "#fff",
                        }}
                    >
                        Local Businesses
                    </InputLabel>
                    <Select
                        id="type"
                        value={businessType}
                        onChange={(e) => {
                            setBusinessType(e?.target?.value);
                            setShowInfoWindow(false);
                            // setSelectedType("existbusiness");
                            setSelectedLocation({ lat: 0, lng: 0, filterType: "existbusiness" });
                            setCenterPos(undefined);
                        }}
                        label="Local Businesses"
                        inputProps={{
                            MenuProps: {
                                MenuListProps: {
                                    sx: {
                                        backgroundColor: "#fff",
                                    },
                                },
                            },
                        }}
                    >
                        <MenuItem value="Bar">Bar</MenuItem>
                        <MenuItem value="Cafe">Cafe</MenuItem>
                        <MenuItem value="Gym">Gym</MenuItem>
                        <MenuItem value="Haircare">Hair Care</MenuItem>
                        <MenuItem value="Restaurant">Restaurant</MenuItem>
                        <MenuItem value="Shoestore">Shoe Store</MenuItem>
                    </Select>
                </FormControl>
                <Divider sx={{ pb: "20px" }} />
            </Box>
            {isLoadingFlag ? (
                <Box sx={{ height: "500px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <CustomSpinner />
                </Box>
            ) : (
                <Box sx={{ p: "20px 14px 0 14px" }} className="list-details">
                    {businessType !== "" && existingBusinessesList?.length ? (
                        existingBusinessesList?.[0].map((list: any, index: any) => (
                            <Box key={list?.google_id} ref={selectedLocation && selectedLocation?.existBusinessId === list?.google_id ? selectedItemRef : null} sx={{ mb: "7px" }}>
                                <Accordion
                                    sx={{
                                        background: "rgba(211,211,211,0.3)",
                                        border: selectedLocation && selectedLocation?.existBusinessId === list?.google_id ? "2px solid rgba(0,0,0,0.7)" : "none",
                                        borderRadius: selectedLocation && selectedLocation?.existBusinessId === list?.google_id ? "6px" : "none",
                                    }}
                                    onClick={() => handleLocationAddress(list?.lat, list?.lng, list?.google_id)}
                                >
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                        <Typography gutterBottom color="#000" align="left" sx={{ fontSize: "1rem" }}>
                                            {list?.business_name}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Box className="flex-simple">
                                            <Typography component="legend" className="listing-heading flex-space">
                                                Business Type
                                            </Typography>
                                            <Typography gutterBottom variant="subtitle1" sx={{ flex: 1 }}>
                                                {list?.business_type ? list?.business_type?.charAt(0).toUpperCase() + list?.business_type?.slice(1) : "Not Available"}
                                            </Typography>
                                        </Box>
                                        <Box className="flex-simple">
                                            <Typography component="legend" className="listing-heading flex-space">
                                                Business Group
                                            </Typography>
                                            <Typography gutterBottom variant="subtitle1" className="flex-space">
                                                {list?.anzsic_group ? list?.anzsic_group : "Not Available"}
                                            </Typography>
                                        </Box>
                                        <Box className="flex-simple">
                                            <Typography component="legend" className="listing-heading flex-space">
                                                Business Division
                                            </Typography>
                                            <Typography gutterBottom variant="subtitle1" className="flex-space">
                                                {list?.anzsic_division ? list?.anzsic_division : "Not Available"}
                                            </Typography>
                                        </Box>
                                        <Box className="flex-simple">
                                            <Typography component="legend" className="listing-heading flex-space">
                                                Business Sub-Division
                                            </Typography>
                                            <Typography gutterBottom variant="subtitle1" className="flex-space">
                                                {list?.anzsic_subdivision ? list?.anzsic_subdivision : "Not Available"}
                                            </Typography>
                                        </Box>
                                        <Box className="flex-simple">
                                            <Typography component="legend" className="listing-heading flex-space">
                                                Operational Status
                                            </Typography>
                                            <Typography gutterBottom variant="subtitle1" className="flex-space">
                                                {list?.operational_status ? list?.operational_status : "Not Available"}
                                            </Typography>
                                        </Box>
                                        <Box className="flex-simple">
                                            <Typography component="legend" className="listing-heading flex-space">
                                                Ranking
                                            </Typography>
                                            <Typography gutterBottom variant="subtitle1" className="flex-space">
                                                {list?.rating ? (
                                                    <Box className="flex-common">
                                                        <span style={{ paddingRight: "4px", marginLeft: "-32px" }}>{list?.rating}</span>
                                                        <Rating name="read-only" value={list?.rating} precision={0.5} readOnly />
                                                    </Box>
                                                ) : (
                                                    "No reviews"
                                                )}
                                            </Typography>
                                        </Box>

                                        {list?.vicinity && (
                                            <Box className="flex-simple">
                                                {/* <Tooltip title="Click to see location"> */}
                                                <Link
                                                    to=""
                                                    style={{ textDecoration: "none", display: "flex", color: "rgba(0,0,238,0.7)" }}
                                                    // onClick={() => handleLocationAddress(list?.lat, list?.lng, list?.google_id)}
                                                >
                                                    <LocationOnIcon />
                                                    {list?.vicinity}
                                                </Link>
                                                {/* </Tooltip> */}
                                            </Box>
                                        )}
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                        ))
                    ) : (
                        <>
                            {businessType !== "" ? (
                                <Typography gutterBottom color={"entry"} sx={{ color: "#009dee", fontWeight: "normal", fontSize: { xs: "1.2rem", md: "1.5rem" } }} align="center">
                                    No existing businesses for {businessType}
                                </Typography>
                            ) : null}
                        </>
                    )}
                </Box>
            )}
        </>
    );
};

export default LocalBusinesses;
