export const POLYGON_OPTIONS = {
  fillColor: "#00000020",
  fillOpacity: 1,
  strokeColor: "#ff000070",
  strokeOpacity: 1,
  strokeWeight: 2,
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};

export const GOOGLE_MAPS_LIBRARIES: ["places", "geometry", "drawing"] = [
  "places",
  "geometry",
  "drawing",
];

export const GOOGLE_API_LOADER_OPTIONS: any = {
  id: "__googleMapsScriptId",
  libraries: GOOGLE_MAPS_LIBRARIES,
  version: "weekly",
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY as string,
};

export const GOOGLE_MAP_API_OPTIONS = {
  mapTypeControl: false,
  streetViewControl: true,
  fullscreenControl: false,
  mapId: "sense_iq_map_2weTacoFoSXpHqwdN1",
};
