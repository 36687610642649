import { createSlice } from "@reduxjs/toolkit";

interface LoginData {
  isLoggedIn : boolean,
  user : {username: string, userid: string, email: string}
}
const initialState: LoginData =  {
  isLoggedIn: false,
  user: {username: "", userid: "", email: ""}
};

export const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        userlogin : (state, action) => {
            state.isLoggedIn = true;
            state.user = action.payload;
        },
        userlogout(state) {
            state.isLoggedIn = false;
            state.user = {username: "", userid: "", email: ""}
        },
    },
  });
    
  export const { userlogin, userlogout } = loginSlice.actions;
  
  export default loginSlice.reducer;

