import React, { useEffect, useState } from "react";
import { Box, Grid, TextField, useTheme, componentSx, Typography, Button, InputAdornment, Visibility, VisibilityOff, IconButton } from "src/UILibrary";
import { useForm } from "react-hook-form";
import { User } from "src/types";
import landingBG from "../../assets/image/grey-map.jpg";
import { Link, useNavigate } from "react-router-dom";
import { userLogin } from "src/modules/api/actions/auth";
import { failed, success, warn } from "src/components/common/Toastify";
import { useDispatch, useSelector } from "react-redux";
import { userlogin } from "src/redux/slices/authService";
import { RootState } from "src/redux/store/store";
import { getUserDefaults } from "src/modules/api/UserData";
import { set_user_defaults } from "src/redux/slices/userDefaultsService";
import { set_user_defaults_status } from "src/redux/slices/userDefaultsStatus";
import LogoDesktop from "../../assets/image/logo-desktop.png";
import Divider from "@mui/material/Divider";
import jwt_decode from "jwt-decode";
import { GoogleLogin } from "@react-oauth/google";

const Login: React.FC = () => {
    const theme = useTheme();
    const { MapContainerSx } = componentSx(theme);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    // const [isCapsLockOn, setIsCapsLockOn] = useState<boolean>(false);
    const navigate = useNavigate();
    const isLoggedIn = useSelector((state: RootState) => state?.auth?.isLoggedIn);
    const Dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<User>({
        mode: "all",
        defaultValues: {
            email: "",
            password: "",
        },
    });
    // function handleCallbackResponse(response: any) {
    //     console.log(jwt_decode(response.credential));
    // }

    // eslint-disable-next-line
    useEffect(() => {
        if (isLoggedIn) {
            navigate("/");
        }
        // window.google?.accounts?.id.initialize({
        //     client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        //     callback: handleCallbackResponse,
        // });
        // window.google.accounts.id.renderButton(document.getElementById("signInDiv"), { type: "standard", theme: "outline", size: "large" });
    }, []);
    //Code For checking caps Lock is on or off
    // const checkCapsLock = (event: React.KeyboardEvent<HTMLInputElement>) => {
    //     if (event.getModifierState("CapsLock")) {
    //         setIsCapsLockOn(true);
    //     } else {
    //         setIsCapsLockOn(false);
    //     }
    // };
    /* ---------@Form Submit handler function------------*/
    const onSubmitHandler = async (dt: User) => {
        // console.log(dt);
        try {
            setSubmitting(true);
            const result = await userLogin(dt);
            if (result?.data?.success === true) {
                success(result?.data?.message);
                Dispatch(userlogin(result?.data?.data));
                // navigate("/");
                /*--------@Api Call for getting user defaults to show Dialog box or not for setting up user defaults-------- */
                const response = await getUserDefaults();
                if (response?.data?.data?.[0] === null) {
                    Dispatch(
                        set_user_defaults([
                            {
                                business_type: "Cafe",
                                lat: -27.4773082,
                                lng: 153.0125446,
                                address: "West-End Brisbane",
                            },
                        ])
                    );
                    Dispatch(set_user_defaults_status());
                    navigate("/explore");
                } else {
                    Dispatch(set_user_defaults_status());
                    Dispatch(set_user_defaults(response?.data?.data));
                    navigate("/explore");
                }
            } else {
                failed(result?.data?.error);
            }
        } catch (error: any) {
            failed(error?.response?.data?.errors?.message || error?.message);
        } finally {
            setSubmitting(false);
        }
    };

    const onSubmitErrorHandler = () => {
        // console.log(errors);
    };

    return (
        <Box
            sx={{
                ...MapContainerSx,
                height: "100vh",
                backgroundImage: `url(${landingBG})`,
            }}
        >
            {/* {isCapsLockOn && warn("Caps Lock is on")} */}
            <Box
                sx={{
                    maxWidth: 460,
                    width: "100%",
                    mx: "auto",
                    p: 4,
                    bgcolor: "google.main",
                    borderRadius: 2,
                    boxShadow: "0 0 10px rgba(0,0,0,.3)",
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
                    <Box sx={{ width: "170px" }}>
                        <img src={LogoDesktop} alt="Senseiq" />
                    </Box>
                </Box>
                <Box sx={{ mt: "20px" }} component="form" onSubmit={handleSubmit(onSubmitHandler, onSubmitErrorHandler)}>
                    <Grid container rowSpacing={3} columnSpacing={2}>
                        -
                        <Grid item xs={12}>
                            <TextField
                                size="small"
                                fullWidth
                                label="Email"
                                {...register("email", {
                                    required: {
                                        value: true,
                                        message: "Email is required",
                                    },
                                    validate: {
                                        email: (v) => /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/.test(v) || "Please enter valid Email address",
                                    },
                                })}
                                helperText={errors?.email?.message}
                                error={!!errors?.email?.message}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                size="small"
                                type={showPassword ? "text" : "password"}
                                // onKeyUp={checkCapsLock}
                                label="Password"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton aria-label="toggle password visibility" onClick={() => setShowPassword(!showPassword)}>
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                {...register("password", {
                                    required: {
                                        value: true,
                                        message: "Password is required",
                                    },
                                    minLength: {
                                        value: 8,
                                        message: "Password length must be at least 8 characters",
                                    },
                                })}
                                helperText={errors?.password?.message}
                                error={!!errors?.password?.message}
                            />
                            {/* {isCapsLockOn && <Typography.Text sx={{ pt: "6px" }}>Caps Lock is on</Typography.Text>} */}
                        </Grid>
                    </Grid>
                    <Grid container rowSpacing={3} columnSpacing={2}>
                        <Grid item xs={12} textAlign="right">
                            <Typography.Text
                                color="primary"
                                sx={{
                                    "& > a": { color: "primary.dark" },
                                    paddingTop: "5px",
                                }}
                            >
                                <Link to={"/forgot-password"}>Forgot Password?</Link>
                            </Typography.Text>
                        </Grid>
                        <Grid item xs={12} textAlign="center">
                            <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                                sx={{
                                    fontSize: { xs: 14, sm: 20 },
                                    borderRadius: 2,
                                }}
                                loading={submitting}
                            >
                                Login
                            </Button>
                        </Grid>

                        <Grid item xs={12} textAlign="center">
                            <Typography.Text
                                color="primary"
                                sx={{
                                    mb: "25px",
                                    "& > a": { color: "primary.dark" },
                                }}
                            >
                                You don't have an account? <Link to={"/register"}>Sign Up</Link>
                            </Typography.Text>
                        </Grid>
                    </Grid>
                    <Divider>
                        <Typography.Text color="primary">OR</Typography.Text>
                    </Divider>
                    <Grid item xs={12} textAlign="center">
                        {/* <Box id="signInDiv" sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: "25px" }}></Box> */}
                        <Box id="signInDiv" sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: "25px" }}>
                            <GoogleLogin
                                onSuccess={(credentialResponse: any) => {
                                    console.log(jwt_decode(credentialResponse?.credential));
                                }}
                                onError={() => {
                                    console.log("Login Failed");
                                }}
                                useOneTap
                            />
                        </Box>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
};

export default Login;
