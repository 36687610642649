import React, { useEffect, useState } from "react";
import Layouts from "../../layouts";
import { Box, useTheme, componentSx, Grid } from "src/UILibrary";
import landingBG from "../../assets/image/grey-map.jpg";
import { LoadMapProvider } from "src/modules/googlemap/LoadMapProvider";
import { failed, success } from "src/components/common/Toastify";
import MapList from "./MapList";
import { addFavourite, deleteFavouriteSite, getAvailableProperties, getLocalBusinesses, getSavedLocationsList } from "src/modules/api/GoogleMapsData";
import { useAutocomplete } from "src/context/AutocompleteContext";
import { IExploreContext, useExplore } from "src/context/ExploreContext";
import { useList } from "src/context/ListContext";

const Explore: React.FC = () => {
    const theme = useTheme();
    const { MapContainerSx } = componentSx(theme);
    /* ------@Loading flags for spinner------- */
    const [isMainLoading, setIsMainLoading] = useState(false);
    /*-----For Available Properties -------- */
    const [isAvlLoading, setIsAvlLoading] = useState(true);
    const [locationProperties, setLocationProperties] = useState<any>();
    /*-----For Saved Locations Properties -------- */
    const [favouriteList, setFavouriteList] = React.useState<any>();
    /*-----For Local Business type -------- */
    const { setExistingBusinessesList } = useList();
    // const userId = useSelector((state: RootState) => state?.auth?.user?.userid);
    /*---@For infowindow Properties---------- */
    const { setSelectedType, setShowInfoWindow, businessType, setInfoWndPos, setIsLoadingFlag } = useExplore() as IExploreContext;
    const { setCenterPos, setBoundaries } = useAutocomplete();

    /*--------After clicking on sidebar location pass lat lng to map component------ */

    /* -----------@Api Call for getting available properties Data---------- */
    const getAvailablePropertiesData = async () => {
        try {
            setIsLoadingFlag(true);
            setIsMainLoading(true);
            const { data } = await getAvailableProperties();
            if (data?.success === true) {
                // setSelectedType("avlproperty");
                setLocationProperties(data?.data?.[0]?.properties);
                setIsAvlLoading(false);
            } else {
                failed(data?.data?.error);
                setIsAvlLoading(false);
            }
        } catch (error: any) {
            failed(error?.data?.errors?.message || error?.message);
        } finally {
            setIsLoadingFlag(false);
            setIsMainLoading(false);
            setIsAvlLoading(false);
        }
    };
    /* ----------@Api Call for getting user favourites list---------------- */
    const getSavedLocations = async () => {
        try {
            // setIsLoadingFlag(true);
            // setIsMainLoading(true);
            const { data } = await getSavedLocationsList();
            if (data?.success === true) {
                setFavouriteList(data?.data?.[0]?.sites);
                // console.log(data?.data);
            } else {
                failed(data?.data?.error);
            }
        } catch (error: any) {
            failed(error?.response?.data?.errors?.message || error?.message);
        } finally {
            // setIsLoadingFlag(false);
            // setIsMainLoading(false);
        }
    };
    /*---------@Delete Favourite Site Handler Function----------- */
    const deleteFavouriteSiteFromList = async (siteid: number | undefined) => {
        try {
            setIsLoadingFlag(true);
            const { data } = await deleteFavouriteSite(siteid);
            if (data?.success === true) {
                await getSavedLocations();
                success(data?.message);
                setShowInfoWindow(false);
            } else {
                failed(data?.error);
            }
        } catch (error: any) {
            failed(error?.response?.data?.errors?.message || error?.message);
        } finally {
            setIsLoadingFlag(false);
        }
    };
    /*----@Add handler function for adding to favourites list------- */
    const handleAddFavourite = async (locationScoreData: any) => {
        try {
            setIsLoadingFlag(true);
            const { data } = await addFavourite(locationScoreData);
            if (data?.success === true) {
                await getSavedLocations();
                success("Successfully Added to favourites");
                setBoundaries([]); // Clearing boundary polygonal
                setCenterPos(undefined); //Clearing marker after added to favourites
            } else {
                failed(data?.data?.error);
            }
        } catch (error: any) {
            failed(error?.response?.data?.errors?.message || error?.message);
        } finally {
            setIsLoadingFlag(false);
            setSelectedType("favourites");
            setInfoWndPos(null);
        }
    };
    /* ----------@Api Call for getting local businesses---------------- */
    const getLocalBusinessesData = async () => {
        try {
            setIsLoadingFlag(true);
            const { data } = await getLocalBusinesses(businessType);
            // console.log("Location Businesses:", data?.data?.[0]);
            if (data?.success === true) {
                setExistingBusinessesList(data?.data);
                // console.log(data?.data);
            } else {
                failed(data?.data?.error || "Something went wrong");
            }
        } catch (error: any) {
            failed(error?.response?.data?.errors?.message || error?.message);
        } finally {
            setIsLoadingFlag(false);
        }
    };
    // eslint-disable-next-line
    /*------@Initial useEffect to get data------- */
    useEffect(() => {
        getSavedLocations();
        getAvailablePropertiesData();
    }, []);
    /*-------@ useEffect hook for getting exist business data on filter------- */
    useEffect(() => {
        if (businessType) {
            getLocalBusinessesData();
        }
    }, [businessType]);

    return (
        <>
            <Layouts>
                <Box
                    sx={{
                        height: "calc(100vh - 60px)",
                    }}
                >
                    {/* --------@Grid container for sidebar and google map along side------ */}
                    <Grid container style={{ width: "100%", height: "100%" }}>
                        <Grid item xs={12} md={4}>
                            <MapList locationProperties={locationProperties} favouriteList={favouriteList} deleteFavouriteSiteFromList={deleteFavouriteSiteFromList} isAvlLoading={isAvlLoading} />
                        </Grid>
                        <Grid item xs={12} md={8} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Box
                                sx={{
                                    ...MapContainerSx,
                                    height: "calc(100vh - 60px)",
                                    width: "100%",
                                    backgroundImage: `url(${landingBG})`,
                                }}
                            >
                                <LoadMapProvider locationProperties={locationProperties} favouriteList={favouriteList} handleAddFavourite={handleAddFavourite} isMainLoading={isMainLoading} />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Layouts>
        </>
    );
};

export default Explore;
