import React from "react";
import "./CustomSpinner.css";

const CustomSpinner: React.FC = () => {
    return (
        <div className="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};

export default CustomSpinner;
