import { Autocomplete } from "@react-google-maps/api";

export const AutocompleteProvider = ({
  children,
  onLoad,
  onPlaceChanged,
}: {
  children: React.ReactElement;
  onLoad: (ac: google.maps.places.Autocomplete) => void;
  onPlaceChanged: () => void;
}) => {
  return (
    <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
      {children}
    </Autocomplete>
  );
};
