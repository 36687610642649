import React, { useState, useMemo, useEffect } from "react";
import {
  ThemeProvider as MuiThemeProvider,
  PaletteMode,
  useTheme,
} from "../UILibrary";

import { getTheme } from "../theme/default";

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

export const ThemeProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const [mode, setMode] = useState<PaletteMode>("light");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          if (prevMode === "light") {
            localStorage.setItem("theme", "dark");
            return "dark";
          }
          localStorage.setItem("theme", "light");
          return "light";
        });
      },
    }),
    []
  );

  useEffect(() => {
    setMode(localStorage.getItem("theme") === "dark" ? "dark" : "light");
  }, []);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <MuiThemeProvider theme={getTheme(mode)}>{children}</MuiThemeProvider>
    </ColorModeContext.Provider>
  );
};

export const useColorMode = () => {
  const { toggleColorMode } = React.useContext(ColorModeContext);
  const { palette } = useTheme();

  return {
    colorMode: palette.mode,
    toggleColorMode,
  };
};
