import { ProfileData } from './../../../pages/Profile/Constants';
import { axiosApiClient } from "../actions/apiClient"

export const userProfile = () =>{
    return axiosApiClient.get(`defaults/get_profile`,{
        withCredentials: true,
        headers: {
          "Access-Control-Allow-Origin": "http://localhost:3000",
        },})
}
export const updateProfile = (data: ProfileData, previeImage?:string) =>{
  const newData = {
    given_name: data?.given_name,
    family_name: data?.family_name,
    picture: previeImage
  }
  return axiosApiClient.post(`defaults/update_profile`, newData, {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "http://localhost:3000",
      },})
}