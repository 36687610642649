import { axiosOsmClient, axiosMapsClient } from "src/modules/api/actions/apiClient";

export const getCoordinates = async (address: string) => {
  const { data } = await axiosOsmClient.get(
    `?q=${address}&polygon_geojson=1&format=json`
  );

  return data;
};

export const getMapPlaceData = async (placeId: string) => {
  const { data } = await axiosMapsClient.get(
    `/json?place_id=${placeId}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`
  );

  return data;
};
